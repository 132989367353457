import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Table } from 'antd';
import { Button, message, Select, Option } from 'antd'
import { CloseOutlined } from '@ant-design/icons';

import styles from './index.module.css'
import { actionCreators } from '../../store';


const CategorizeAllManagementTable = ({
    categoryDetailsData, setCategoryDetailsData, loading, setIsShow,
    paramsName, paramsCategoryData, category, handleRequest, isVerified
}) => {
    const dispatch = useDispatch()
    let active_uuid = useSelector(state => state.get('admin').get('active_uuid'))
    let active_navbar = useSelector(state => state.get('admin').get('active_navbar'))
    const [updating, setUpdating] = useState(false)
    const [data, setData] = useState([])
    useEffect(() => {
        const transform_detail_data = categoryDetailsData.map((item) => ({
          ...item,
          current_param: paramsName,
        }));

        setData(transform_detail_data);

        console.log("categoryDetailsData = ", categoryDetailsData);
        console.log("transform_detail_data = ", transform_detail_data);
    }, [categoryDetailsData, paramsName]);

    const [optionData, setOptionData] = useState([])
    useEffect(() => {
        const transform_params_data = paramsCategoryData.map((item) => {
            return {
                value: item.name,
                label: item.name,
            }
        })

        setOptionData(transform_params_data);
    }, [paramsCategoryData]);

    const handleSelectChange = (id, value) => {
        let new_data = data.map(item => {
            if (item.key === id) {
                item.current_param = value
            }

            return item
        })
        setData(new_data)
    }

    const columns = [
        {
            title: '序号',
            dataIndex: 'key',
            key: 'key',
            render: (text, record, index) => index + 1,
        },
        {
            title: '用户的提问',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: '当前分类项',
            key: 'current_param',
            render: (text, record) => (
              <Select
                value={record.current_param}
                style={{ width: 150 }}
                onChange={value => handleSelectChange(record.key, value)}
                options={optionData}
              />
            ),
        },
    ]

    const exportCategoryDetailsData = () => {
        if (active_navbar === 'categorize_user_management') {
            let args = `?category_name=${paramsName}&category=${category}&user=${active_uuid}&is_verified=${isVerified === 'yes' ? true : false}`
            let url = '/api/admin/message/category/export' + args
            window.location.href = url;
        } else if (active_navbar === 'categorize_all_management') {
            let args = `?category_name=${paramsName}&category=${category}&is_verified=${isVerified === 'yes' ? true : false}`
            let url = '/api/admin/message/category/export' + args
            window.location.href = url;
        }
    }

    const updateCategoryData = () => {
        let filter_data = data.filter(item => item.current_param !== paramsName)
        let result_data = filter_data.map((item) => {
            return {
                "message_id": item.key,
                "category_name": item.current_param,
            }
        })

        let d = {
            "messages_category": result_data
        }
        dispatch(actionCreators.updateMessageCategory(d, category))
            .then(data => {
                message.success("数据更新成功！")
                closeCategoryTable()
                handleRequest()
            })
            .catch(error => {})
            .finally(() => {  })
    }

    const closeCategoryTable = () => {
        setIsShow(false)
        setCategoryDetailsData([])
    }

    return (
        <div className={styles.floating_window}>
            <Button
                shape="circle"
                icon={<CloseOutlined />}
                onClick={closeCategoryTable}
                className={styles.custom_close_button}
            />
            <Button
                type="primary"
                onClick={exportCategoryDetailsData}
                disabled={data.length === 0 ? true : false}
                className={styles.custom_export_button}
            >
                导出当前的数据
            </Button>
            {
                updating ? (
                    <Button
                        type="primary"
                        loading
                        className={styles.custom_update_button}
                    >
                        修改中...
                    </Button>
                ) : (
                    <Button
                        type="primary"
                        onClick={updateCategoryData}
                        disabled={data.length === 0 ? true : false}
                        className={styles.custom_update_button}
                    >
                        修改分类
                    </Button>
                )
            }
            <div className={styles.custom_params_name}>分类：{paramsName}</div>
            <div className={styles.floating_window_content}>
                <Table
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                />
            </div>
        </div>

    )
}

export default CategorizeAllManagementTable