import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Tour } from 'antd';

import styles from './index.module.css'
import { actionCreators } from '../../store'
import MyButton from '../../../../common/MyButton';

function CustomTourTitle({ title }) {
    return (
        <div className={styles.item_title}>
            { title }
        </div>
    )
}

function CustomTourDescription({ description }) {
    return (
        <div className={styles.item_description}>
            { description }
        </div>
    )
}

function CustomTourIndicators({ current, total, updateCurrent }) {
    // 调用传入的 updateCurrent 函数来更新 custom_current
    const handleChange = (newVal) => {
        updateCurrent(newVal)
    };

    const arr = new Array(total).fill(null); // 使用 null 填充数组，也可以用其他值替换

    return (
        <div className={styles.item_indicators}>
            {
                arr.map((item, index) => {
                    if (current === index) {
                        return (
                            <div
                                key={index}
                                className={`${styles.item_indicators_circle} ${styles.item_indicators_circle_active}`}
                            >
                            </div>
                        )
                    }

                    return (
                        <div
                            key={index}
                            className={styles.item_indicators_circle}
                            onClick={() => {handleChange(index)}}
                        >
                        </div>
                    )
                })
            }
        </div>
    )
}

class BeginnerGuide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            custom_current: 0,
        }
    }

    generateSteps() {
        let r = [
            { id: "tour1", title: '我的灵石', placement: 'right', description: '你可以在这里查看你的灵石状态，等级，经验，等等。', },
            { id: "tour2", title: '等级与经验', placement: 'right', description: '你可以多多与灵石对话，随着对话次数的上升，你可以升级，并且解锁更多隐藏功能，获得更多权限。', },
            { id: "tour3", title: '灵石商店', placement: 'right', description: '你可以在这里购买你所需要的室内设计知识，我们把各种知识功能打包成了“组件”，购买后的组件将在背包内，可以认真挑选您所需要的设计知识哦。', },
            { id: "tour4", title: '我的背包', placement: 'right', description: '你在商店买来的组件，会出现在你的背包内，把背包里的“组件”装备到灵石上，就可以有更多针对性的高质量知识来辅助你做室内设计。', },
            { id: "tour5", title: '一键添加桌面', placement: 'top', description: '一键将灵石AI添加到您的桌面，使用灵石更加方便。', },
            { id: "tour6", title: '灵石客服', placement: 'top', description: '在任何使用上的问题，对灵石不懂的问题，都可以问这个灵石小客服。', },
        ]

        let result = r.map((item, index) => {
            let next_children
            let children_text = ''
            if (r.length !== index + 1) {
                children_text = '继续'
            } else {
                children_text = '完成'
            }

            next_children = (
                <MyButton
                    width={'100%'}
                    height={'38px'}
                    text={
                        <>
                            <span className={styles.next_butn_text}>{children_text}</span>
                            <div className={styles.next_butn_icon}></div>
                        </>
                    }
                    type="primary"
                />
                // <div className={styles.next_butn}>
                //     <span className={styles.next_butn_text}>{children_text}</span>
                //     <div className={styles.next_butn_icon}></div>
                // </div>
            )

            return {
                className: `custom_tour_${item.id}`,
                title: <CustomTourTitle title={item.title}></CustomTourTitle>,
                description: <CustomTourDescription description={item.description}></CustomTourDescription>,
                target: () => {
                    return document.querySelector(`#${item.id}`)
                },
                placement: item.placement,
                arrow: false,
                prevButtonProps: {
                    // children: <div>上一步</div>,
                    style: {display: 'none'}
                },
                nextButtonProps: {
                    children: next_children,
                    // onClick?: () => void;
                    className: r.length !== index + 1 ? 'custom_next_butn_box' : 'custom_next_butn_done_box',
                    style: {width: '100%'},
                },
            }
        })

        return result
    }

    render() {
        let steps = this.generateSteps()

        return (
            <Tour
                open={this.props.is_show_guide}
                onClose={this.onTourClose.bind(this)}
                onChange={this.onTourChange.bind(this)}
                steps={steps}
                current={this.state.custom_current}
                rootClassName={"custom_tour"}
                disabledInteraction={true}
                indicatorsRender={(current, total) => {
                    return (
                        <CustomTourIndicators
                            current={current}
                            total={total}
                            updateCurrent={this.updateCurrent.bind(this)}
                        >
                        </CustomTourIndicators>
                    )
                }}
            />
        )
    }

    onTourClose() {
        this.props.handleIsShowGuideAction(false)
        let updateInfo = this.props.updateInfo.toJS()
        if (updateInfo.has_update) {
            // 在关闭了新手指南之后，将会开启当前的更新内容信息
            this.props.handleIsUpdateInfoModalOpenAction(true)
        }
    }

    onTourChange(e) {
        console.log("onTourChange e = ", e);
        this.updateCurrent(e)
    }

    updateCurrent(newVal) {
        this.setState({ custom_current: newVal });
    }
}

const mapStateToProps = (state) => {
    return {
        is_show_guide: state.get('home').get('is_show_guide'),
        updateInfo: state.get('home').get('updateInfo'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsShowGuideAction(status) {
            dispatch(actionCreators.isShowGuideAction(status))
        },
        handleIsUpdateInfoModalOpenAction(status) {
            dispatch(actionCreators.isUpdateInfoModalOpenAction(status))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BeginnerGuide)