import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Tooltip } from 'antd'

import styles from './index.module.css'
import { actionCreators } from '../../store'
import { actionCreators as homeActionCreators } from '../../../home/store'
import backToHome from '../../../../statics/collect_back_to_home.png'


class Header extends Component {
    render() {
        return (
            <div className={styles.container}>
                <div className={styles.left}>
                    <Tooltip placement="bottom" title={"主界面"}>
                        <div
                            className={styles.back_img_box}
                            onClick={this.handleBachToHome.bind(this)}
                        >
                            <img src={backToHome} className={styles.back_img} />
                        </div>
                    </Tooltip>
                </div>


                <div className={`${styles.right} ${this.props.is_show_block_usage ? styles.fade_in : ''}`}>
                    <Tooltip placement="bottom" title={"反馈"}>
                        <div
                            className={styles.img_box}
                            onClick={this.handleFeedbackModal.bind(this)}
                        >
                            <div
                                className={`${styles.img} ${styles.feedback_img}`}
                                data-alt="反馈"
                            >
                            </div>
                            <div className={styles.search_box_mask}></div>
                        </div>
                    </Tooltip>

                    <Tooltip placement="bottom" title={"收藏"}>
                        <div
                            className={styles.img_box}
                            onClick={this.handleGoToCollection.bind(this)}
                        >
                            <div className={`${styles.img} ${styles.collect_img}`} data-alt="收藏"></div>
                            <div
                                className={styles.search_box_mask}
                            >
                            </div>
                        </div>
                    </Tooltip>

                    <Tooltip placement="bottom" title={"设置"}>
                        <div
                            className={styles.img_box}
                            onClick={this.handleUserInfoSetting.bind(this)}
                        >
                            <div className={`${styles.img} ${styles.setting_img}`} data-alt="设置"></div>
                            <div
                                className={styles.search_box_mask}
                            >
                            </div>
                        </div>
                    </Tooltip>
                </div>
            </div>
        );
    }

    onSearchChange(e) {
        let value = e.target.value
        this.props.handleMenuSearchContentAction(value)
    }

    handleFeedbackModal() {
        this.props.handleIsFeedbackModalOpenAction(true)
    }

    handleGoToCollection() {
        window.location.reload(false)
        // this.props.history.push({ pathname: "/collect" })

        // 使用 HashRouter 时，URL 应该包括 '#'
        // const route = '/collect';
        // const url = window.location.origin + '/#' + route;
        // const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        // if (newWindow) newWindow.opener = null;
    }

    handleUserInfoSetting() {
        this.props.handleUserinfoModalOpenAction()
    }

    handleBachToHome() {
        // 跳转到主页的默认画面
        this.props.handleIsShowScreenPersonalOverview(true)
        this.props.handleIsShowScreenStoneModelMall(false)
        this.props.handleIsShowScreenPersonalBackpack(false)
        this.props.handleExpertRelatedAction()

        this.props.history.push({ pathname: "/home" })
    }
}

const mapStateToProps = (state) => {
    return {
        menu_search_content: state.get('home').get('menu_search_content'),
        is_show_block_usage: state.get('home').get('is_show_block_usage'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsThinkingAction(status) {
            dispatch(homeActionCreators.isThinkingAction(status))
        },
        handleIsFeedbackModalOpenAction(status) {
            dispatch(homeActionCreators.isFeedbackModalOpenAction(status))
        },
        handleMenuSearchContentAction(value) {
            dispatch(homeActionCreators.menuSearchContentAction(value))
        },
        handleUserinfoModalOpenAction() {
            dispatch(homeActionCreators.handleUserinfoModalOpenAction())
        },
        handleIsShowScreenPersonalOverview(status) {
            dispatch(homeActionCreators.isShowScreenPersonalOverview(status))
        },
        handleIsShowScreenStoneModelMall(status) {
            dispatch(homeActionCreators.isShowScreenStoneModelMall(status))
        },
        handleIsShowScreenPersonalBackpack(status) {
            dispatch(homeActionCreators.isShowScreenPersonalBackpack(status))
        },
        handleExpertRelatedAction() {
            dispatch(homeActionCreators.expertRelatedAction())
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))