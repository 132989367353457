import { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import styles from './index.module.css'
import { Tooltip, Input } from 'antd'
import onlineImg from '../../../../statics/ai_assistant_online.png'
import sendImg from '../../../../statics/send.png'
import sendActiveImg from '../../../../statics/send_active.png'
import thingkingLoadingImg from '../../../../statics/thinking_loading.png'
import { actionCreators } from '../../store'
import AssistantMessageList from '../AssistantMessageList'


function AssistantMessageNone({handleFetchRequest}) {
    const dispatch = useDispatch()
    const message_none_list = [
        "如何更换我的组件？",
        "如何赚取更多经验？",
        "如何查找聊天记录？",
    ]

    const handleClick = (value) => {
        dispatch(actionCreators.setAssistantPrompt(value))
        handleFetchRequest(value)
    }

    return (
        <div className={styles.message_none_box}>
            <div className={styles.message_none_title}>猜你也许想问：</div>
            <div className={styles.message_none_list}>
                {
                    message_none_list.map((item) => {
                        return (
                            <div
                                className={styles.message_none_butn}
                                key={item}
                                onClick={() => {handleClick(item)}}
                            >
                                {`"“${item}” → `}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

function AIAssistant({isShow, setIsShow}) {
    const dispatch = useDispatch()
    let assistant_chat_data_immutable = useSelector(state => state.get('home').get('assistant_chat_message_data'))
    let assistant_prompt = useSelector(state => state.get('home').get('assistant_prompt'))
    let is_assistant_ask = useSelector(state => state.get('home').get('is_assistant_ask'))
    // 用 useRef 来存储 AbortController 实例
    const abortControllerRef = useRef(null);

    const onSubmit = () => {
        if (is_assistant_ask || assistant_prompt.trim() === '') {
            return
        }

        handleFetchRequest(assistant_prompt)
    }

    const handleFetchRequest = (prompt) => {
        // 创建一个新的 AbortController 实例
        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;
        dispatch(actionCreators.sendAssistantAskAction(prompt, signal))
    }

    const pressEnter = (e) => {
        if (e.key === 'Enter' && e.keyCode === 13 && e.shiftKey) {
            // === 无操作 ===
        } else if (e.key === 'Enter' && e.keyCode === 13) {
            onSubmit()
            e.preventDefault() //禁止回车的默认换行
        }
    }

    const handleStopResponding = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
            console.log('Fetch request aborted');

            dispatch(actionCreators.cancelAssistantFetchRequest())
        }
    }

    return (
        <div className={styles.ai_main}>
            <div className={styles.ai_header_box}>
                <img src={onlineImg} className={styles.ai_header_online} alt={"icon"} />
                <div className={styles.ai_header_text_box}>
                    <div className={styles.ai_header_title}>客服</div>
                    <div className={styles.ai_header_status}>在线</div>
                </div>
                <div
                    className={styles.ai_header_close_box}
                    onClick={() => {setIsShow(!isShow)}}
                >
                    <div className={styles.ai_header_close}></div>
                </div>
            </div>
            <div className={styles.ai_content_box}>
                {
                    assistant_chat_data_immutable.toJS().length === 0 ? (
                        <AssistantMessageNone handleFetchRequest={handleFetchRequest} />
                    ) : (
                        <div className={styles.ai_content_list_box}>
                            <AssistantMessageList />
                        </div>
                    )
                }
            </div>
            <div className={styles.ai_input_box}>
                <div className={styles.textarea_box}>
                    <Input.TextArea
                        value={assistant_prompt}
                        size="large"
                        placeholder={is_assistant_ask ? "正在思考中..." : "输入问题..."}
                        autoSize={{
                            maxRows: 10
                        }}
                        onPressEnter={pressEnter}
                        onChange={(e) => { dispatch(actionCreators.setAssistantPrompt(e.target.value)) }}
                        className={styles.custom_textarea}
                    />
                    {
                        is_assistant_ask ? (
                            <div
                                className={styles.thinking_loading_box}
                                onClick={handleStopResponding}
                            >
                                <img
                                    src={thingkingLoadingImg}
                                    className={styles.circle_img}
                                />
                                <div className={styles.square_img}></div>
                            </div>
                        ) : (
                            assistant_prompt.trim() !== '' && !is_assistant_ask ? (
                                <img
                                    src={sendActiveImg}
                                    className={styles.send_butn_img}
                                    onClick={onSubmit}
                                    alt="发送"
                                />
                            ) : (
                                <img
                                    src={sendImg}
                                    className={styles.send_butn_img}
                                />
                            )
                        )
                    }
                </div>
            </div>
        </div>
    )
}


function AIAssistantButn() {
    const [isShow, setIsShow] = useState(false)

    return (
        <div className={styles.main}>
            <Tooltip placement="top" title={"客服"}>
                <div
                    className={styles.butn_box}
                    onClick={() => {setIsShow(!isShow)}}
                >
                </div>
            </Tooltip>

            {
                isShow ? (
                    <div className={styles.chat_box}>
                        <AIAssistant
                            isShow={isShow}
                            setIsShow={setIsShow}
                        />
                    </div>
                ) : null
            }
        </div>
    )
}

export default AIAssistantButn