import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useMemo } from 'react'
import { Button, Modal, Card, message, Form, Select, Option, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import styles from './index.module.css'
import dollarImg from '../../../../statics/overview_shopping_dollar_icon.png'
import { actionCreators } from '../../store'
import ComponentListedModalAdd from '../ComponentListedModalAdd'
import ComponentListedModalEdit from '../ComponentListedModalEdit'
import ModalImageView from '../../../../common/ModalImageView'
import ComponentListedModalPicContentAdd from '../ComponentListedModalPicContentAdd'
import addIcon from '../../../../statics/components_list_add_icon.png'
import ComponentListedPublish from '../ComponentListedPublish'
import unselectImg from '../../../../statics/card_box_unselected.png'
import selectImg from '../../../../statics/card_box_select.png'
import editImg from '../../../../statics/card_box_edit.png'
import deleteImg from '../../../../statics/card_box_delete.png'
import putOnShelvesImg from '../../../../statics/card_box_put_on_shelves.png'
import takeOffShelvesImg from '../../../../statics/card_box_take_off_shelves.png'
import { messageImageScoreLikeAction } from '../../../home/store/actionCreators';
import MyPasswordConfirmModal from '../../../../common/MyPasswordConfirmModal'


function TransferModal({isModalOpen, setIsModalOpen, detailList, setDetailList, transferAction}) {
    const dispatch = useDispatch()
    const [creating, setCreating] = useState(false)
    const [selectOptions, setSelectOptions] = useState([])
    let component_picture_listed = useSelector(state => state.get('admin').get('component_picture_listed'))
    let current_edit_component_name = useSelector(state => state.get('admin').get('current_edit_component_name'))
    const [form] = Form.useForm()

    useEffect(() => {
        let l = []
        component_picture_listed.toJS().forEach(item => {
            if (!item.is_active) {
                let v = {
                    value: item.name,
                    label: item.name,
                }

                l.push(v)
            }
        })

        setSelectOptions(l)
    }, [component_picture_listed, isModalOpen])

    const onFinish = (values) => {
        console.log('Success:', values);
        let destination = values.destination
        if (destination === current_edit_component_name) {
            message.info("目的地为当前所在组件，如有需要，请重新选择！")
        } else {
            setCreating(true)
            let filenames = []
            detailList.forEach((item) => {
                if (item.is_card_select) {
                    filenames.push(item.name)
                }
            })

            let d = {
                "component_name": current_edit_component_name,
                "dest_component_name": destination,
                "filenames": filenames
            }
            if (transferAction === 'move') {
                dispatch(actionCreators.movePictureComponentContent(d))
                    .then((data) => {
                        dispatch(actionCreators.getComponentContentList(current_edit_component_name))
                            .then((data) => {
                                let d = data.data.map((item) => {
                                    item.is_card_select = false
                                    return item
                                })
                                setDetailList(d)
                            })
                            .catch((err) => {console.log(err);})
                            .finally(() => {})
                        handleCloseModalLocal()
                    })
                    .catch(() => {})
                    .finally(() => {
                        setCreating(false)
                    })
            } else {
                dispatch(actionCreators.copyPictureComponentContent(d))
                    .then((data) => {
                        dispatch(actionCreators.getComponentContentList(current_edit_component_name))
                            .then((data) => {
                                let d = data.data.map((item) => {
                                    item.is_card_select = false
                                    return item
                                })
                                setDetailList(d)
                            })
                            .catch((err) => {console.log(err);})
                            .finally(() => {})
                        handleCloseModalLocal()
                    })
                    .catch(() => {})
                    .finally(() => {
                        setCreating(false)
                    })
            }
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const handleCloseModalLocal = () => {
        form.resetFields();
        setIsModalOpen(false)
        setSelectOptions([])
    }

    return (
        <>
            <Modal
                title={transferAction === 'move' ? "选择要移动的目的地" : "选择要复制的目的地"}
                open={isModalOpen}
                onOk={handleCloseModalLocal}
                onCancel={handleCloseModalLocal}
                // okText="确认"
                // cancelText="取消"
                footer={false}
                destroyOnClose={true}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    preserve={false}
                    form={form}
                >
                    <Form.Item
                        name="source_point"
                        label="当前所在组件"
                    >
                        <p>
                            { current_edit_component_name }
                        </p>
                    </Form.Item>
                    <Form.Item
                        name="destination"
                        label="目的地"
                        rules={[{ required: true, message: '请选择一个目的地!' }]}
                    >
                        <Select
                            placeholder="请选择一个目的地"
                            options={selectOptions}
                        >
                        </Select>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        {
                            creating ? (
                                <Button type="primary" loading>
                                    添加中
                                </Button>
                            ) : (
                                <Button type="primary" htmlType="submit">
                                    添加
                                </Button>
                            )
                        }
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

const ComponentList = ({setIsModalOpen, setIsEditModalOpen, setDetailList, setPageStatus}) => {
    const dispatch = useDispatch()
    let component_picture_listed = useSelector(state => state.get('admin').get('component_picture_listed'))
    // const [componentsList, setComponentsList] = useState([])
    const [unpublishedList, setUnpublishedList] = useState([])
    const [publishedList, setPublishedList] = useState([])
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false)
    const [confirmAction, setConfirmAction] = useState(null);

    useEffect(() => {
        let unpublish_list = []
        let publish_list = []
        component_picture_listed.toJS().forEach(element => {
            if (element.is_active) {
                publish_list.push(element)
            } else {
                unpublish_list.push(element)
            }
        });

        setUnpublishedList(unpublish_list)
        setPublishedList(publish_list)
    }, [component_picture_listed])

    const handleOpenModal = () => {
        dispatch(actionCreators.currentEditComponentType('picture_kb'))
        setIsModalOpen(true)
    }

    let currentComponent = useMemo(() => {
        let list = component_picture_listed.toJS()
        let obj = list.find(item => item.has_select === true)
        return obj ? obj : {}
    }, [component_picture_listed])

    const handleEdit = (e, name) => {
        e.stopPropagation(); // 防止点击事件冒泡到 Menu.Item
        dispatch(actionCreators.currentEditComponentName(name))
        dispatch(actionCreators.currentEditComponentType('picture_kb'))
        setIsEditModalOpen(true)
    }

    const handleDelete = (e, name) => {
        e.stopPropagation(); // 防止点击事件冒泡到 Menu.Item

        Modal.confirm({
            title: '删除组件',
            icon: <ExclamationCircleOutlined />,
            content: '请确认是否要删除该组件以及该组件的相关文件？',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                setConfirmAction(() => {
                    // 这里定义你在 LoginFormModal 确认后想要执行的动作
                    return (code) => {
                        let data = {
                            name: name
                        }
                        let authcode = `${code}`
                        dispatch(actionCreators.deleteAdminSingleComponent(data, authcode))
                    }
                })
                setIsPasswordModalOpen(true)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const handleDetail = (e, name) => {
        e.stopPropagation(); // 防止点击事件冒泡到 Menu.Item
        dispatch(actionCreators.currentEditComponentName(name))
        dispatch(actionCreators.currentEditComponentType('picture_kb'))

        dispatch(actionCreators.getComponentContentList(name))
            .then((data) => {
                let d = data.data.map((item) => {
                    item.is_card_select = false
                    return item
                })
                setDetailList(d)
            })
            .catch((err) => {console.log(err);})
            .finally(() => {})
        setPageStatus('detail')
    }

    const handlePublish = (e, id, name) => {
        e.stopPropagation(); // 防止点击事件冒泡到 Menu.Item

        handleClickComponent(id)
        dispatch(actionCreators.currentEditComponentName(name))
        dispatch(actionCreators.currentEditComponentType('picture_kb'))
        setPageStatus('publish')
    }

    const handleUnpublish = (e, name) => {
        e.stopPropagation(); // 防止点击事件冒泡到 Menu.Item

        Modal.confirm({
            title: '下架组件',
            icon: <ExclamationCircleOutlined />,
            content: `请确认是否要下架该组件《${name}》以及该组件的相关文件？`,
            okText: '确认',
            cancelText: '取消',
            onOk() {
                setConfirmAction(() => {
                    // 这里定义你在 LoginFormModal 确认后想要执行的动作
                    return (code) => {
                        let authcode = `${code}`
                        dispatch(actionCreators.getAdminComponentDeactivate(name, authcode))
                            .then(() => {
                                dispatch(actionCreators.getComponentListed('picture_kb'))
                            })
                            .catch(() => {})
                            .finally(() => {})
                    }
                })
                setIsPasswordModalOpen(true)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const handleClickComponent = (id) => {
        let list = component_picture_listed.toJS()
        let new_list = list.map((item) => {
            if (item.id === id) {
                item.has_select = true
            } else {
                item.has_select = false
            }
            return item
        })
        dispatch(actionCreators.componentPictureListedAction(new_list))
    }

    const handlePasswordOk = (code) => {
        if (confirmAction) {
            confirmAction(code)
        }
        setIsPasswordModalOpen(false)
    }

    return (
        <div className={styles.container_component_content}>
            <div className={styles.container_content_left}>
                <div className={styles.left_components_title}>未上架</div>
                <div className={styles.left_components}>
                    {
                        unpublishedList.map((item) => {
                            return (
                                <div
                                    className={styles.shopping_model_item_box}
                                    key={item.id}
                                    onClick={(e) => {handleDetail(e, item.name)}}
                                >
                                    <div
                                        className={styles.shopping_model_item}
                                    >
                                        <div className={styles.shopping_model_img_box}>
                                            <img src={item.avatar} className={styles.shopping_model_img} alt="icon" />
                                        </div>

                                        <div className={styles.shopping_model_text_box}>
                                            <div className={styles.shopping_model_text_title}>
                                                {item.name}
                                            </div>
                                            <div className={styles.shopping_model_text_info}>
                                                {item.overview}
                                            </div>
                                        </div>

                                        <div className={styles.action_list}>
                                            <Tooltip placement="top" title={"编辑"}>
                                                <div className={styles.card_action_img_box}>
                                                    <img
                                                        src={editImg}
                                                        className={styles.card_action_img}
                                                        alt="edit"
                                                        onClick={(e) => {handleEdit(e, item.name)}}
                                                    />
                                                </div>
                                            </Tooltip>
                                            <Tooltip placement="top" title={"删除"}>
                                                <div className={styles.card_action_img_box}>
                                                    <img
                                                        src={deleteImg}
                                                        className={styles.card_action_img}
                                                        alt="delete"
                                                        onClick={(e) => {handleDelete(e, item.name)}}
                                                    />
                                                </div>
                                            </Tooltip>
                                            <Tooltip placement="top" title={"上架"}>
                                                <div className={styles.card_action_img_box}>
                                                    <img
                                                        src={putOnShelvesImg}
                                                        className={styles.card_action_img}
                                                        alt="上架"
                                                        onClick={(e) => {handlePublish(e, item.id, item.name)}}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div
                        className={styles.components_add}
                        onClick={handleOpenModal}
                    >
                        <img src={addIcon} alt="add icon" className={styles.components_add_img} />
                    </div>
                </div>
            </div>

            <div className={styles.container_content_right}>
                <div className={styles.left_components_title}>已上架</div>
                <div className={styles.left_components}>
                    {
                        publishedList.map((item) => {
                            return (
                                <div
                                    className={styles.shopping_model_item_box}
                                    key={item.id}
                                    onClick={(e) => {handleDetail(e, item.name)}}
                                >
                                    <div
                                        className={styles.shopping_model_item}
                                    >
                                        <div className={styles.shopping_model_img_box}>
                                            <img src={item.avatar} className={styles.shopping_model_img} alt="icon" />
                                        </div>

                                        <div className={styles.shopping_model_text_box}>
                                            <div className={styles.shopping_model_text_title}>
                                                {item.name}
                                            </div>
                                            <div className={styles.shopping_model_text_info}>
                                                {item.overview}
                                            </div>
                                        </div>

                                        <div className={styles.action_list}>
                                            <Tooltip placement="top" title={"编辑"}>
                                                <div className={styles.card_action_img_box}>
                                                    <img
                                                        src={editImg}
                                                        className={styles.card_action_img}
                                                        alt="edit"
                                                        onClick={(e) => {handleEdit(e, item.name)}}
                                                    />
                                                </div>
                                            </Tooltip>
                                            {/* <Tooltip placement="top" title={"删除"}>
                                                <div className={styles.card_action_img_box}>
                                                    <img
                                                        src={deleteImg}
                                                        className={styles.card_action_img}
                                                        alt="delete"
                                                        onClick={(e) => {handleDelete(e, item.name)}}
                                                    />
                                                </div>
                                            </Tooltip> */}
                                            <Tooltip placement="top" title={"下架"}>
                                                <div className={styles.card_action_img_box}>
                                                    <img
                                                        src={takeOffShelvesImg}
                                                        className={styles.card_action_img}
                                                        alt="下架"
                                                        onClick={(e) => {handleUnpublish(e, item.name)}}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    {/* <div
                        className={styles.components_add}
                        onClick={handleOpenModal}
                    >
                        <img src={addIcon} alt="add icon" className={styles.components_add_img} />
                    </div> */}
                </div>
            </div>

            <MyPasswordConfirmModal
                isModalOpen={isPasswordModalOpen}
                setIsModalOpen={setIsPasswordModalOpen}
                handlePasswordOk={handlePasswordOk}
            />
        </div>
    )
}

const ComponentListDetail = ({setPageStatus, detailList, setDetailList, setIsImageModalOpen, setShowImageUrl, setIsAddModalOpen, setAddModalStatus, setCurrentDetailItem, setIsTransferModalOpen, setTransferAction}) => {
    const dispatch = useDispatch()
    const [isBatch, setIsBatch] = useState(false)
    let current_edit_component_name = useSelector(state => state.get('admin').get('current_edit_component_name'))
    let component_picture_listed = useSelector(state => state.get('admin').get('component_picture_listed'))

    let currentComponent = useMemo(() => {
        let list = component_picture_listed.toJS()
        let obj = list.find(item => item.name === current_edit_component_name)
        return obj ? obj : {}
    }, [component_picture_listed, current_edit_component_name])

    const handleImageClick = (url) => {
        setShowImageUrl(url)
        setIsImageModalOpen(true)
    }

    const handleReback = () => {
        setPageStatus('component')
        setDetailList([])
    }

    const handleBatchSelect = () => {
        setIsBatch(true)
    }

    const handleUnbatchSelect = () => {
        setIsBatch(false)
        setDetailList(prevList => {
            return prevList.map(item => {
                return { ...item, is_card_select: false }
            });
        })
    }

    const handleBatchTransfer = () => {
        let list = []
        detailList.forEach((item) => {
            if (item.is_card_select) {
                list.push(item.name)
            }
        })

        if (list.length === 0) {
            message.info("请先选择要删除的图片！")
        } else {
            setTransferAction('move')
            setIsTransferModalOpen(true)
        }
    }

    const handleBatchCopy = () => {
        let list = []
        detailList.forEach((item) => {
            if (item.is_card_select) {
                list.push(item.name)
            }
        })

        if (list.length === 0) {
            message.info("请先选择要删除的图片！")
        } else {
            setTransferAction('copy')
            setIsTransferModalOpen(true)
        }
    }

    const handleBatchDelete = () => {
        let list = []
        detailList.forEach((item) => {
            if (item.is_card_select) {
                list.push(item.name)
            }
        })

        if (list.length === 0) {
            message.info("请先选择要删除的图片！")
        } else {
            Modal.confirm({
                title: '删除文件',
                icon: <ExclamationCircleOutlined />,
                content: '请确认是否要批量删除文件？',
                okText: '确认',
                cancelText: '取消',
                onOk() {
                    console.log('OK');
                    let d = {
                        component_name: current_edit_component_name,
                        filenames: list,
                    }
                    dispatch(actionCreators.deleteComponentContent(d))
                        .then((data) => {
                            dispatch(actionCreators.getComponentContentList(current_edit_component_name))
                                .then((data) => {
                                    let d = data.data.map((item) => {
                                        item.is_card_select = false
                                        return item
                                    })
                                    setDetailList(d)
                                    setIsBatch(false)
                                })
                                .catch((err) => {console.log(err);})
                                .finally(() => {})
                        })
                        .catch((e) => {console.log(e);})
                        .finally(() => {})
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
    }

    const handleCardSelect = (id) => {
        setDetailList(prevList => {
            return prevList.map(item => {
              if (item.id === id) {
                if (item.is_card_select) {
                    return { ...item, is_card_select: false };
                } else {
                    return { ...item, is_card_select: true };
                }
              }
              return item;
            });
        })
    }

    const handleSelectAll = () => {
        setDetailList(prevList => {
            return prevList.map(item => {
                return { ...item, is_card_select: true };
            });
        })
    }

    const handleCancelSelectAll = () => {
        setDetailList(prevList => {
            return prevList.map(item => {
                return { ...item, is_card_select: false };
            });
        })
    }

    const handleAdd = () => {
        setAddModalStatus('add')
        setIsAddModalOpen(true)
    }

    const handleEdit = (item) => {
        setCurrentDetailItem(item)
        setAddModalStatus('edit')
        setIsAddModalOpen(true)
    }

    const handleDelete = (file_name) => {
        console.log("删除");

        Modal.confirm({
            title: '删除文件',
            icon: <ExclamationCircleOutlined />,
            content: '请确认是否要删除文件："' + file_name + '" ？',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                console.log('OK');
                let d = {
                    component_name: current_edit_component_name,
                    filenames: [file_name],
                }
                dispatch(actionCreators.deleteComponentContent(d))
                    .then((data) => {
                        dispatch(actionCreators.getComponentContentList(current_edit_component_name))
                            .then((data) => {
                                let d = data.data.map((item) => {
                                    item.is_card_select = false
                                    return item
                                })
                                setDetailList(d)
                            })
                            .catch((err) => {console.log(err);})
                            .finally(() => {})
                    })
                    .catch((e) => {console.log(e);})
                    .finally(() => {})
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    return (
        <div className={styles.container_detail_list_main}>
            <div className={styles.container_action_list}>
                <div className={styles.action_list_left}>
                    <Button onClick={handleReback}>返回</Button>
                    {/* <Button type="primary" onClick={handleAdd}>添加</Button> */}
                </div>
                <div className={styles.action_list_right}>
                    {
                        isBatch ? (
                            <>
                                <Button onClick={handleCancelSelectAll}>取消全选</Button>
                                <Button onClick={handleSelectAll} style={{marginRight: '30px'}}>全选</Button>
                                {
                                    currentComponent?.is_active ? (
                                        <Button onClick={handleBatchCopy} type='primary'>复制到</Button>
                                    ) : (
                                        <>
                                            <Button onClick={handleBatchTransfer} type='primary'>移动到</Button>
                                            <Button onClick={handleBatchDelete} danger>批量删除</Button>
                                        </>
                                    )
                                }

                                <Button onClick={handleUnbatchSelect}>取消</Button>
                            </>
                        ) : (
                            <Button onClick={handleBatchSelect}>选择</Button>
                        )
                    }
                </div>
            </div>
            <div className={styles.container_detail_list_box}>
                <div className={styles.container_detail_list}>
                    {
                        currentComponent.is_active ? null : (
                            <div className={styles.card_box_add} onClick={handleAdd}>
                                <img src={addIcon} alt="add icon" className={styles.components_add_img} />
                            </div>
                        )
                    }

                    {
                        detailList.map((item) => {
                            return (
                                <div
                                    className={`${styles.card_box} ${isBatch ? styles.card_box_select : null}`}
                                    key={item.id}
                                >
                                    <Card
                                        hoverable
                                        style={{
                                            width: 265,
                                            height: 300,
                                        }}
                                        cover={<img onClick={() => {handleImageClick(item.image_meta.url)}} alt="card icon" src={item.image_meta.thumbnail_url} className={styles.card_img} />}
                                        className={styles.detail_card}
                                    >
                                        <Card.Meta title={""} description={item.name} />
                                        <div className={styles.card_action_box}>
                                            <div className={styles.card_label_box}>
                                                {
                                                    item?.text && Object.keys(item.text).length !== 0 ? (
                                                        <div className={styles.card_tag}>已打标签</div>
                                                    ) : (
                                                        <div className={styles.card_untag}>未打标签</div>
                                                    )
                                                }
                                            </div>

                                            <Tooltip placement="top" title={"编辑"}>
                                                <div className={styles.card_action_img_box}>
                                                    <img src={editImg} className={styles.card_action_img} alt="edit" onClick={() => {handleEdit(item)}} />
                                                </div>
                                            </Tooltip>
                                            {
                                                currentComponent.is_active ? null : (
                                                    <>
                                                        <Tooltip placement="top" title={"删除"}>
                                                            <div className={styles.card_action_img_box}>
                                                                <img src={deleteImg} className={styles.card_action_img} alt="delete" onClick={() => {handleDelete(item.name)}} />
                                                            </div>
                                                        </Tooltip>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </Card>

                                    <div className={styles.card_box_cover} onClick={() => { handleCardSelect(item.id) }}>
                                        <div className={styles.select_img_box}>
                                            {
                                                item.is_card_select ? (
                                                    <img src={selectImg} className={styles.card_box_cover_select} alt="select" />
                                                ) : (
                                                    <img src={unselectImg} className={styles.card_box_cover_unselect} alt="unselect" />
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default function ComponentListedPicture() {
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [pageStatus, setPageStatus] = useState('component')       // component detail public
    const [detailList, setDetailList] = useState([])
    const [isImageModalOpen, setIsImageModalOpen] = useState(false)
    const [showImageUrl, setShowImageUrl] = useState('')
    const [currentDetailItem, setCurrentDetailItem] = useState({})
    const [isAddModalOpen, setIsAddModalOpen] = useState(false)
    const [addModalStatus, setAddModalStatus] = useState('add')     // add edit
    const [isTransferModalOpen, setIsTransferModalOpen] = useState(false)
    const [transferAction, setTransferAction] = useState('move')    // move copy
    let current_edit_component_name = useSelector(state => state.get('admin').get('current_edit_component_name'))

    useEffect(() => {
        dispatch(actionCreators.getComponentListed('document_kb'))
        dispatch(actionCreators.getComponentListed('picture_kb'))
        dispatch(actionCreators.getComponentCategory())
    }, [])

    const pageMap = {
        component: <ComponentList
            setIsModalOpen={setIsModalOpen}
            setIsEditModalOpen={setIsEditModalOpen}
            setDetailList={setDetailList}
            setPageStatus={setPageStatus}
        />,
        detail: <ComponentListDetail
            setPageStatus={setPageStatus}
            detailList={detailList}
            setDetailList={setDetailList}
            setIsImageModalOpen={setIsImageModalOpen}
            setShowImageUrl={setShowImageUrl}
            setIsAddModalOpen={setIsAddModalOpen}
            setAddModalStatus={setAddModalStatus}
            setCurrentDetailItem={setCurrentDetailItem}
            setIsTransferModalOpen={setIsTransferModalOpen}
            setTransferAction={setTransferAction}
        />,
        publish: <ComponentListedPublish
            setPageStatus={setPageStatus}
        />,
    };

    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <div className={styles.container_header}>
                    {
                        pageStatus === 'detail' ? (current_edit_component_name) : "图片组件"
                    }
                </div>

                <div className={styles.container_content}>
                    { pageMap[pageStatus] || null }
                </div>
            </div>

            <ComponentListedModalAdd
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                currentComponentType={'picture_kb'}
            />
            <ComponentListedModalEdit
                isModalOpen={isEditModalOpen}
                setIsModalOpen={setIsEditModalOpen}
                currentComponentType={'picture_kb'}
            />
            <ModalImageView
                isModalOpen={isImageModalOpen}
                setIsModalOpen={setIsImageModalOpen}
                showImageUrl={showImageUrl}
                setShowImageUrl={setShowImageUrl}
            />
            <ComponentListedModalPicContentAdd
                isModalOpen={isAddModalOpen}
                setIsModalOpen={setIsAddModalOpen}
                currentDetailItem={currentDetailItem}
                setCurrentDetailItem={setCurrentDetailItem}
                addModalStatus={addModalStatus}
                detailList={detailList}
                setDetailList={setDetailList}
            />
            <TransferModal
                isModalOpen={isTransferModalOpen}
                setIsModalOpen={setIsTransferModalOpen}
                detailList={detailList}
                setDetailList={setDetailList}
                transferAction={transferAction}
            />
        </div>
    )
}