import { fromJS } from 'immutable'

import * as actionTypes from './actionTypes'

const defaultState = fromJS({
    // 聊天记录相关
    all_chat_data: {
        today: [
            // {
            //     id: '',
            //     conversation_id: '',
            //     conversation_name: '新的对话',
            //     conversation_name_edit: '',
            //     created_at: "2024-06-07 04:00:11",
            //     is_edit_name: false,
            //     favorites_type: '1',
            //     topic_id: '113',
            //     has_get_images: false,
            //     active: false,
            //     data: [
            //         {
            //             id: '',
            //             message_id: '123',
            //             topic_message_id: '123',
            //             query_at: '2024-03-14 14:49:31',
            //             updated_at: '2024-03-14 14:49:31',
            //             text: '你是谁？你是谁？你是谁？你是谁？你是谁？你是谁？你是谁？你是谁？你是谁？你是谁？你是谁？你是谁？你是谁？你是谁？你是谁？你是谁？',
            //             text_extra_img: '',
            //             text_extra_search: '',
            //             image_ids: [],
            //             search_result: [],
            //             image_urls: [],
            //             suggestion: [],
            //             status: 'pass',
            //             role: 'user',
            //             message_score: -1,
            //             is_edit: false,
            //             edit_text: '',
            //         },
            //         {
            //             id: '',
            //             message_id: '122',
            //             topic_message_id: '122',
            //             query_at: '2024-03-14 14:49:31',
            //             updated_at: '2024-03-14 14:49:31',
            //             text: '我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI我是AI',
            //             text_extra_img: '',
            //             text_extra_search: '',
            //             image_ids: [
            //                 {
            //                     "index": 887,
            //                     "url": "",
            //                     "id": 1,
            //                     "score": 1,
            //                 },
            //             ],
            //             search_result: [
            //                 {
            //                     begin_page: 0,
            //                     doc_key: "",
            //                     doc_type: "",
            //                     fullText: "",
            //                     height: 0,
            //                     image: "",
            //                     length: 0,
            //                     payload: "",
            //                     pictures: [{}],
            //                     publishDate: "",
            //                     seeMoreUrl: "",
            //                     showName: "",
            //                     snippet: "",
            //                     summary: "",
            //                     title: "",
            //                     width: 0,
            //                 }
            //             ],
            //             image_urls: [],
            //             suggestion: [{
            //                 author: "bot",
            //                 createdAt: "",
            //                 messageType: "Suggestion",
            //                 text: "你能聊天以外的事情吗",
            //                 timestamp: "",
            //             },],
            //             status: 'pass',
            //             role: 'ai',
            //             message_score: -1,
            //             is_edit: false,
            //             edit_text: '',
            //         },
            //         {
            //             id: '',
            //             message_id: '1221',
            //             topic_message_id: '1221',
            //             query_at: '2024-03-14 14:49:31',
            //             updated_at: '2024-03-14 14:49:31',
            //             text: '',
            //             text_extra_img: '',
            //             text_extra_search: '',
            //             image_ids: [],
            //             search_result: [],
            //             image_urls: [],
            //             suggestion: [],
            //             status: 'loading',
            //             role: 'ai',
            //             message_score: -1,
            //             is_edit: false,
            //             edit_text: '',
            //         },
            //     ]
            // },
        ],
        third_day: [],
        seventh_day: [],
        thirtieth_day: [],
    },
    chat_date_list: [
        { value: 'today', label: '今天', days_start: 0, days_end: 0, },
        { value: 'third_day', label: '过去3天', days_start: 1, days_end: 3, },
        { value: 'seventh_day', label: '过去7天', days_start: 4, days_end: 7, },
        { value: 'thirtieth_day', label: '过去30天', days_start: 8, days_end: 30 },
    ],
    active_date: '',     // today third_day seventh_day thirtieth_day
    active_topicid: '',
    thinking_message_id: '',    // 正在问答的 message 的 id

    prompt: '',
    is_thinking: false,
    is_stop_fetch_responding: false,
    isUserInfoModalOpen: false,
    isFeedbackModalOpen: false,
    isFeedbackDoneModalOpen: false,
    feedback_score: 0,
    feedback_content: '',
    feedback_face_list: [
        { name: 'wearyFace', score: 1 },
        { name: 'frowningFace', score: 2 },
        { name: 'smilingFace', score: 3 },
        { name: 'grinningFace', score: 4 },
        { name: 'heartEyesFace', score: 5 },
    ],
    language: 'chinese',
    theme: 'daytime',       // daytime darknight
    avatar_upload_filelist: [],

    is_zoom: false,
    zoom_butn_text: '关闭侧边栏',

    is_show_intro_video: false,
    is_show_guide: false,
    is_show_image_mover: false,
    is_show_block_usage: false,
    is_show_user_research: false,
    user_research_data: {
        step: 1,    // 1 2 3 4
        gender: '',
        age: '',
        age_options: [
            {
                value: '18-24',
                label: '18-24',
            },
            {
                value: '25-34',
                label: '25-34',
            },
            {
                value: '35-44',
                label: '35-44',
            },
            {
                value: '45-54',
                label: '45-54',
            },
            {
                value: '55-64',
                label: '55-64',
            },
            {
                value: '65岁以上',
                label: '65岁以上',
            },
        ],
        occupation: '',
        occupation_options: [
            {
                value: '学生',
                label: '学生',
            },
            {
                value: '室内设计师',
                label: '室内设计师',
            },
            {
                value: '建筑设计师',
                label: '建筑设计师',
            },
            {
                value: '自由职业者',
                label: '自由职业者',
            },
            {
                value: '个人爱好者',
                label: '个人爱好者',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
        city: '',
        city_options: [
            { value: '北京', label: '北京', },
            { value: '上海', label: '上海', },
            { value: '广州', label: '广州', },
            { value: '深圳', label: '深圳', },
            { value: '成都', label: '成都', },
            { value: '武汉', label: '武汉', },
            { value: '西安', label: '西安', },
            { value: '大连', label: '大连', },
            { value: '海口', label: '海口', },
        ],
    },
    menu_search_content: '',

    // 图片展示相关
    is_message_image_visible: false,
    message_item_image_path: '',

    // 聊天信息相关
    suggestion_list: [
        // {
        //     author: "bot",
        //     createdAt: "",
        //     messageType: "Suggestion",
        //     text: "你能聊天以外的事情吗",
        //     timestamp: "",
        // },
    ],
    allow_program_scroll: true,

    // 用户登录信息
    nickname: '',

    isUpdateInfoModalOpen: false,
    updateInfo: {
        // has_update: false,
        // release: {
        //     content: "1.0.0",
        //     id: 1,
        //     published_at: "2024-07-09 17:35:03",
        //     release_v: "1.0.0",
        //     meta_data: {
        //         images: [],
        //     },
        // },
    },

    // 自定义知识库相关
    knowledge_base_menu: [],

    // 经验值相关
    isModalExperienceOpen: false,
    isExperienceConfettiOpen: false,
    current_experience: 0,
    levelThresholds: [],
                  // [50, 150, 450, 1350, 4000, 12000, 36000]
                  // [Lv1, Lv2, Lv3, Lv4, Lv5, Lv6, Lv7]

    is_show_screen_personal_overview: false,
    is_show_screen_stone_model_mall: false,
    is_show_screen_personal_backpack: false,
    mall_components_list: [
        // {
        //     name: '基础功能',
        //     overview: '基础模型擅长为你的疑问，提供最基本的信息。，提供最基本的信息。',
        //     description: '基础模型擅长为你的疑问，提供最基本的信息。，提供最基本的信息。',
        //     avatar_tb: '',
        //     category: "业务模型",
        //     avatar: '',
        //     id: 11,
        //     desc: '基础功能 desc',
        //     permissions: ["read", "write", "execute", "delete"],     // execute 代表已购买
        //     is_limit_time_free: true,
        //     has_select: true,
        //     has_add: 0,
        // },
        // {
        //     name: '联网功能',
        //     overview: '联网印记可以让你的原石随时接入互联网搜索答案。',
        //     description: '联网印记可以让你的原石随时接入互联网搜索答案。',
        //     avatar_tb: '',
        //     category: "辅助模型",
        //     avatar: '',
        //     id: 12,
        //     desc: '联网功能 desc',
        //     permissions: ["read", "write", "execute", "delete"],
        //     is_limit_time_free: true,
        //     has_select: false,
        //     has_add: 0,
        // },
        // {
        //     name: '知识库',
        //     overview: '联网印记可以让你的原石随时接入互联网搜索答案。联网印记可以让你的原石随时接入互联网搜索答案。',
        //     description: '联网印记可以让你的原石随时接入互联网搜索答案。',
        //     avatar_tb: '',
        //     category: "学科模型",
        //     avatar: '',
        //     id: 13,
        //     desc: 'desc',
        //     permissions: ["read", "write", "execute", "delete"],
        //     is_limit_time_free: true,
        //     has_select: false,
        //     has_add: 0,
        // },
    ],
    select_mall_component_name: '',
    mall_components_categorys: [
        // {
        //     label: "所有",
        //     active: true,
        // },
        // {
        //     label: "灵石模型",
        //     active: false,
        // }
    ],
    level_space_data: {
        'lv1': 2,
        'lv2': 4,
        'lv3': 5,
        'lv4': 6,
        'lv5': 8,
        'lv6': 10,
    },

    // 灵石助手
    assistant_prompt: '',
    is_assistant_ask: false,
    assistant_chat_message_data: [
        // {
        //     id: '',
        //     message_id: '123',
        //     topic_message_id: '123',
        //     query_at: '2024-03-14 14:49:31',
        //     updated_at: '2024-03-14 14:49:31',
        //     text: '你是谁？',
        //     text_extra_img: '',
        //     text_extra_search: '',
        //     image_ids: [],
        //     search_result: [],
        //     image_urls: [],
        //     suggestion: [],
        //     status: 'pass',
        //     role: 'user',
        //     message_score: -1,
        // },
        // {
        //     id: '',
        //     message_id: '122',
        //     topic_message_id: '122',
        //     updated_at: '2024-03-14 14:49:31',
        //     text: '我是AI',
        //     text_extra_img: '',
        //     text_extra_search: '',
        //     image_ids: [
        //         {
        //             "index": 887,
        //             "url": "",
        //             "id": 1,
        //             "score": 1,
        //         },
        //     ],
        //     search_result: [
        //         {
        //             begin_page: 0,
        //             doc_key: "",
        //             doc_type: "",
        //             fullText: "",
        //             height: 0,
        //             image: "",
        //             length: 0,
        //             payload: "",
        //             pictures: [{}],
        //             publishDate: "",
        //             seeMoreUrl: "",
        //             showName: "",
        //             snippet: "",
        //             summary: "",
        //             title: "",
        //             width: 0,
        //         }
        //     ],
        //     image_urls: [],
        //     suggestion: [{
        //         author: "bot",
        //         createdAt: "",
        //         messageType: "Suggestion",
        //         text: "你能聊天以外的事情吗",
        //         timestamp: "",
        //     },],
        //     status: 'pass',
        //     role: 'ai',
        //     message_score: -1,
        // },
        // {
        //     id: '',
        //     message_id: '1123',
        //     topic_message_id: '1123',
        //     updated_at: '2024-03-14 14:49:31',
        //     text: '你是谁？',
        //     text_extra_img: '',
        //     text_extra_search: '',
        //     image_ids: [],
        //     search_result: [],
        //     image_urls: [],
        //     suggestion: [],
        //     status: 'pass',
        //     role: 'user',
        //     message_score: -1,
        // },
        // {
        //     id: '',
        //     message_id: '1221',
        //     topic_message_id: '1221',
        //     updated_at: '2024-03-14 14:49:31',
        //     text: '',
        //     text_extra_img: '',
        //     text_extra_search: '',
        //     image_ids: [],
        //     search_result: [],
        //     image_urls: [],
        //     suggestion: [],
        //     status: 'loading',
        //     role: 'ai',
        //     message_score: -1,
        // },
    ],
    allow_assistant_content_scroll: true,
    chat_count_data: {
        current_count: 0,
        max_count: 60
    },
    chat_count_error_message: '',
})

const reducer = (state=defaultState, action) => {
    switch(action.type) {
        case actionTypes.SET_PROMPT_ACTION:
            return state.set('prompt', action.value)
        case actionTypes.IS_THINKING_ACTION:
            return state.set('is_thinking', action.status)
        case actionTypes.IS_STOP_FETCH_RESPONDING_ACTION:
            return state.set('is_stop_fetch_responding', action.status)
        case actionTypes.ALL_CHAT_DATA_ACTION:
            return state.merge({
                'all_chat_data': fromJS(action.data),
            })
        case actionTypes.ACTIVE_TOPICID_ACTION:
            return state.merge({
                active_date: action.active_date,
                active_topicid: action.active_topicid,
            })
        case actionTypes.IS_ZOOM_ACTION:
            return state.set('is_zoom', action.status)
        case actionTypes.IS_USERINFO_MODAL_OPEN_ACTION:
            return state.set('isUserInfoModalOpen', action.status)
        case actionTypes.IS_FEEDBACK_MODAL_OPEN_ACTION:
            return state.set('isFeedbackModalOpen', action.status)
        case actionTypes.FEEDBACK_SCORE_ACTION:
            return state.set('feedback_score', action.value)
        case actionTypes.FEEDBACK_CONTENT_ACTION:
            return state.set('feedback_content', action.value)
        case actionTypes.LANGUAGE_ACTION:
            return state.set('language', action.value)
        case actionTypes.THEME_ACTION:
            return state.set('theme', action.value)
        case actionTypes.AVATAR_UPLOAD_FILELIST_ACTION:
            return state.merge({
                'avatar_upload_filelist': fromJS(action.data),
            })
        case actionTypes.ZOOM_BUTN_TEXT_ACTION:
            return state.set('zoom_butn_text', action.value)
        case actionTypes.IS_SHOW_INTRO_VIDEO_ACTION:
            return state.set('is_show_intro_video', action.status)
        case actionTypes.IS_SHOW_GUIDE_ACTION:
            return state.set('is_show_guide', action.status)
        case actionTypes.IS_SHOW_IMAGE_MOVER_ACTION:
            return state.set('is_show_image_mover', action.status)
        case actionTypes.IS_SHOW_BLOCK_USAGE_ACTION:
            return state.set('is_show_block_usage', action.status)
        case actionTypes.IS_SHOW_USER_RESEARCH_ACTION:
            return state.set('is_show_user_research', action.status)
        case actionTypes.USER_RESEARCH_DATA_ACTION:
            return state.merge({
                'user_research_data': fromJS(action.data),
            })
        case actionTypes.IS_FEEDBACK_DONE_MODAL_OPEN_ACTION:
            return state.set('isFeedbackDoneModalOpen', action.status)
        case actionTypes.MENU_SEARCH_CONTENT_ACTION:
            return state.set('menu_search_content', action.value)
        case actionTypes.MESSAGE_IMAGE_VISIABLE_ACTION:
            return state.set('is_message_image_visible', action.status)
        case actionTypes.MESSAGE_ITEM_IMAGE_PATH_ACTION:
            return state.set('message_item_image_path', action.value)
        case actionTypes.SUGGESTION_LIST_ACTION:
            return state.merge({
                'suggestion_list': fromJS(action.data),
            })
        case actionTypes.ALLOW_PROGRAM_SCROLL_ACTION:
            return state.set('allow_program_scroll', action.status)
        case actionTypes.NICKNAME_ACTION:
            return state.set('nickname', action.value)
        case actionTypes.RESET_STATE:
            return defaultState
        case actionTypes.THINKING_MESSAGE_ID_ACTION:
            return state.set('thinking_message_id', action.value)
        case actionTypes.IS_UPDATE_INFO_MODAL_OPEN_ACTION:
            return state.set('isUpdateInfoModalOpen', action.status)
        case actionTypes.SET_UPDATE_INFO_ACTION:
            return state.merge({
                'updateInfo': fromJS(action.data),
            })
        case actionTypes.KNOWLEDGE_BASE_LIST_ACTION:
            return state.merge({
                'knowledge_base_menu': fromJS(action.data),
            })
        case actionTypes.SET_IS_MODAL_EXPERIENCE_OPEN:
            return state.set('isModalExperienceOpen', action.status)
        case actionTypes.SET_IS_EXPERIENCE_CONFETTI_OPEN:
            return state.set('isExperienceConfettiOpen', action.status)
        case actionTypes.SET_CURRENT_EXPERIENCE:
            return state.set('current_experience', action.value)
        case actionTypes.SET_LEVEL_THRESHOLDS:
            return state.merge({
                'levelThresholds': fromJS(action.data),
            })
        case actionTypes.IS_SHOW_SCREEN_PERSONAL_OVERVIEW:
            return state.set('is_show_screen_personal_overview', action.status)
        case actionTypes.IS_SHOW_SCREEN_STONE_MODEL_MALL:
            return state.set('is_show_screen_stone_model_mall', action.status)
        case actionTypes.IS_SHOW_SCREEN_PERSONAL_BACKPACK:
            return state.set('is_show_screen_personal_backpack', action.status)
        case actionTypes.SET_MALL_COMPONENTS_LIST:
            return state.merge({
                'mall_components_list': fromJS(action.data),
            })
        case actionTypes.SELECT_MALL_COMPONENT_NAME:
            return state.set('select_mall_component_name', action.value)
        case actionTypes.SET_MALL_COMPONENTS_CATEGORYS:
            return state.merge({
                'mall_components_categorys': fromJS(action.data),
            })
        case actionTypes.SET_ASSISTANT_PROMPT:
            return state.set('assistant_prompt', action.value)
        case actionTypes.SET_IS_ASSISTANT_ASK:
            return state.set('is_assistant_ask', action.status)
        case actionTypes.SET_ASSISTANT_CHAT_MESSAGE_DATA:
            return state.merge({
                'assistant_chat_message_data': fromJS(action.data),
            })
        case actionTypes.ALLOW_ASSISTANT_CONTENT_SCROLL:
            return state.set('allow_assistant_content_scroll', action.status)
        case actionTypes.SET_CHAT_COUNT_DATA:
            return state.merge({
                'chat_count_data': fromJS(action.data),
            })
        case actionTypes.SET_CHAT_COUNT_ERROR_MESSAGE:
            return state.set('chat_count_error_message', action.value)
        default:
            return state
    }
}

export default reducer