import React, { Component } from 'react';
import { connect } from 'react-redux'

import styles from './index.module.css'
import { actionCreators } from '../../store'
import loading from '../../../../statics/ai_link_stone.gif'
import { detectDevice } from '../../../../utils';


class ImageMover extends Component {
    async componentDidMount() {
        // 使用了 await 关键字，需要确保外层函数被标记为 async
        await new Promise(resolve => setTimeout(resolve, 1000));
        // 让主页的其他元素有一个渐出效果，并且屏蔽用户点击画面元素
        // await new Promise(resolve => setTimeout(resolve, 500));
        this.props.handleIsShowBlockUsageAction(true)
        this.props.handleIsZoomAction(true)
        this.props.handleIsShowImageMoverAction(false)

        // 让侧边栏显示
        await new Promise(resolve => setTimeout(resolve, 500));
        this.props.handleIsZoomAction(false);

        // 让新手指南显示
        await new Promise(resolve => setTimeout(resolve, 500));
        if (!detectDevice() === 'computer_browser') {
            // 在浏览器则显示出新手指南
            this.props.handleIsShowGuideAction(true);
        } else {
            let updateInfo = this.props.updateInfo.toJS()
            if (updateInfo.has_update) {
                // 在关闭了新手指南之后，将会开启当前的更新内容信息
                this.props.handleIsUpdateInfoModalOpenAction(true)
            }
        }
        this.props.handleIsShowBlockUsageAction(false)

        // setTimeout(() => {
        //     this.props.handleIsShowImageMoverAction(false);
        //     setTimeout(() => {
        //         this.props.handleIsZoomAction(false)
        //         setTimeout(() => {
        //             this.props.handleIsShowGuideAction(true)
        //         }, 500)
        //     }, 500)
        // }, 1000);
    }

    render() {
        return (
            <div className={styles.image_container}>
                <div className={styles.img_box}>
                    <img
                        src={loading}
                        alt="Moving up"
                        className={styles.move_up_animation}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        updateInfo: state.get('home').get('updateInfo'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsShowImageMoverAction(status) {
            dispatch(actionCreators.isShowImageMoverAction(status))
        },
        handleIsZoomAction(status) {
            dispatch(actionCreators.isZoomAction(status))
        },
        handleIsShowGuideAction(status) {
            dispatch(actionCreators.isShowGuideAction(status))
        },
        handleIsShowBlockUsageAction(status) {
            dispatch(actionCreators.isShowBlockUsageAction(status))
        },
        handleIsUpdateInfoModalOpenAction(status) {
            dispatch(actionCreators.isUpdateInfoModalOpenAction(status))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageMover)
