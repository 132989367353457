import React, { Component, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, message, Upload, Card, Table, Form, Input, Space } from 'antd';

import styles from './index.module.css'
import { actionCreators } from '../../store';
import { generateRandomId } from '../../../../utils'
import addIcon from '../../../../statics/components_list_add_icon.png'
import MyZoomPanPinch from '../../../../common/MyZoomPanPinch'

const CustomForm = ({tableData, setTableData, form}) => {
    const onFinish = (values) => {
        console.log('Success:', values);

        let obj = tableData.find(item => item.name === values.new_name)
        if (obj) {
            message.warning("存在相同的属性名，请更改！！")
        } else {
            let newItem = {
                'name': values.new_name,
                'value': values.new_value,
                'id': generateRandomId(),
            }
            setTableData(prevData => [...prevData, newItem]);
            form.resetFields()
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    return (
        <Form
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            style={{
                minWidth: '100%',
                maxWidth: '100%',
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            preserve={false}
            form={form}
        >
            <Form.Item
                label="分类"
                labelAlign="left"
                name="new_name"
                rules={[
                    {
                        required: true,
                        message: '请输入新的分类名称',
                    },
                ]}
            >
                <Input.TextArea
                    style={{
                        resize: 'none',
                        width: '100%',
                        height: 80,
                    }}
                    placeholder="请输入新的分类名称..."
                    className={styles.custom_textarea}
                />
            </Form.Item>

            <Form.Item
                label="描述标签"
                labelAlign="left"
                name="new_value"
                rules={[
                    {
                        required: true,
                        message: '请输入新的描述标签',
                    },
                ]}
            >
                <Input.TextArea
                    style={{
                        resize: 'none',
                        width: '100%',
                        height: 80,
                    }}
                    placeholder="请输入新的描述标签..."
                    className={styles.custom_textarea}
                />
            </Form.Item>

            <Button type="primary" htmlType="submit">
                添加
            </Button>
        </Form>
    )
}

const CustomTable = ({tableData, setTableData}) => {
    const columns = [
        {
            title: '分类',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: '描述标签',
            key: 'value',
            dataIndex: 'value',
        },
        {
            title: '操作',
            key: 'id',
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <Button type="primary" danger onClick={() => {onDelete(record.id)}}>删除</Button>
                    </Space>
                )
            },
        },
    ]

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    const onDelete = (id) => {
        setTableData((prevData) => prevData.filter(item => item.id !== id))
    }

    return (
        <Table
            columns={columns}
            dataSource={tableData}
            onChange={onChange}
        />
    )
}

function ComponentListedModalPicContentAdd({isModalOpen, setIsModalOpen, currentDetailItem, setCurrentDetailItem, addModalStatus, detailList, setDetailList}) {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    let component_picture_listed = useSelector(state => state.get('admin').get('component_picture_listed'))
    let current_edit_component_name = useSelector(state => state.get('admin').get('current_edit_component_name'))
    const [creating, setCreating] = useState(false)
    const [imageName, setImageName] = useState('')
    const [fileList, setFileList] = useState([])
    const [imagePreview, setImagePreview] = useState('');
    const [tableData, setTableData] = useState([])

    useEffect(() => {
        let obj = currentDetailItem.text
        if (obj) {
            let l = Object.keys(obj)
            let temp_data = l.map((item) => {
                return {
                    name: item,
                    value: obj[item],
                    id: generateRandomId()
                }
            })
            setTableData(temp_data)
        }
        if (currentDetailItem.image_meta?.url || currentDetailItem.image_meta?.thumbnail_url) {
            setImagePreview(currentDetailItem.image_meta.url ? currentDetailItem.image_meta.url : currentDetailItem.image_meta.thumbnail_url)
        }
        setImageName(currentDetailItem?.name || '')
    }, [currentDetailItem])

    let currentComponent = useMemo(() => {
        let list = component_picture_listed.toJS()
        let obj = list.find(item => item.name === current_edit_component_name)
        return obj ? obj : {}
    }, [component_picture_listed, current_edit_component_name])

    const handleCloseModalLocal = () => {
        form.resetFields()
        setIsModalOpen(false)
        setFileList([])
        setImagePreview('')
        setTableData([])
        setCurrentDetailItem({})
    }

    const handleUploadChange = (info) => {
        let newFileList = [...info.fileList];
        // 只保留最后一个选择的文件
        newFileList = newFileList.slice(-1);
        console.log("newFileList", newFileList);
        setFileList(newFileList)
        setImageName(newFileList[0]?.name || '')

        // 使用 FileReader 读取文件并生成 Data URL
        const reader = new FileReader();
        reader.onload = (e) => {
            setImagePreview(e.target.result);
        };
        reader.readAsDataURL(info.file);
    }

    const handleSubmit = () => {
        if (addModalStatus === 'add') {
            handleCreateSubmit()
        } else {
            handleUpdateSubmit()
        }
    }

    const handleCreateSubmit = () => {
        if (imageName === '') {
            message.warning("图片名称不能为空")
        } else if (imagePreview === '') {
            message.warning("请选择图片，图片不能为空！")
        } else if (tableData.length === 0) {
            message.warning("图片描述信息不能为空！请添加选项")
        } else {
            let new_obj = {}
            tableData.forEach((item) => {
                new_obj[item.name] = item.value
            })
            let formData = new FormData();
            formData.append('component_name', current_edit_component_name)
            formData.append('image', fileList[0].originFileObj)
            formData.append('metadata', JSON.stringify(new_obj))

            setCreating(true)
            dispatch(actionCreators.createPictureComponentContent(formData))
                .then((d) => {
                    dispatch(actionCreators.getComponentContentList(current_edit_component_name))
                        .then((data) => { setDetailList(data.data) })
                        .catch((err) => {console.log(err);})
                        .finally(() => {handleCloseModalLocal()})
                })
                .catch((e) => {console.log(e);})
                .finally(() => {setCreating(false)})
        }
    }

    const handleUpdateSubmit = () => {
        let new_obj = {}
        tableData.forEach((item) => {
            new_obj[item.name] = item.value
        })
        let old_obj = currentDetailItem.text
        if (imageName === '') {
            message.warning("图片名称不能为空")
        } else if (tableData.length === 0) {
            message.warning("图片描述信息不能为空！请添加选项")
        } else if (imageName !== currentDetailItem.name && detailList.find(item => item.name === imageName)) {
            message.warning("新图片的名称已存在，请修改当前图片名称！")
        } else {
            let formData = new FormData();
            formData.append('component_name', current_edit_component_name)
            formData.append('filename', currentDetailItem.name)
            if (JSON.stringify(old_obj) !== JSON.stringify(new_obj)) {
                formData.append('metadata', JSON.stringify(new_obj))
            }

            if (fileList.length !== 0) {
                formData.append('image', fileList[0].originFileObj)
            }

            if (fileList.length === 0 && imageName !== currentDetailItem.name) {
                formData.append('new_filename', imageName)
            }

            setCreating(true)
            dispatch(actionCreators.updatePictureComponentContent(formData))
                .then((d) => {
                    dispatch(actionCreators.getComponentContentList(current_edit_component_name))
                        .then((data) => { setDetailList(data.data) })
                        .catch((err) => {console.log(err);})
                        .finally(() => {handleCloseModalLocal()})
                })
                .catch((e) => {console.log(e);})
                .finally(() => {setCreating(false)})
        }
    }

    return (
        <>
            <Modal
                title={addModalStatus === 'add' ? "往组件中添加新的图片" : "编辑该组件的图片"}
                open={isModalOpen}
                onOk={handleCloseModalLocal}
                onCancel={handleCloseModalLocal}
                // okText="确认"
                // cancelText="取消"
                centered
                footer={false}
                destroyOnClose={true}
                width={1700}
                className={styles.modal_container}
            >
                <div className={styles.container}>
                    <div className={styles.main}>
                        <div className={styles.main_left}>
                            <div className={styles.preview_img_container}>
                                <div className={styles.preview_img_box}>
                                    {
                                        imagePreview ? (
                                            <MyZoomPanPinch
                                                img_url={imagePreview ? imagePreview : currentDetailItem.image_meta?.thumbnail_url}
                                            />
                                            // <img
                                            //     alt="image preview"
                                            //     src={imagePreview ? imagePreview : currentDetailItem.image_meta?.thumbnail_url}
                                            //     className={styles.preview_img}
                                            // />
                                        ) : (
                                            <div className={styles.card_add_img_box}>
                                                <Upload.Dragger
                                                    style={{width: '700px', height: '400px', margin: '0 auto' }}
                                                    fileList={fileList}
                                                    beforeUpload={(file) => {
                                                        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                                                        if (!isJpgOrPng) {
                                                        message.error('目前只允许上传 JPG/PNG 的图片！');
                                                        }
                                                        const isLt10M = file.size / 1024 / 1024 < 10;
                                                        if (!isLt10M) {
                                                            message.error('图片目前限制要小于 10MB！');
                                                        }
                                                        return false;
                                                    }}
                                                    showUploadList={false}  // 不显示上传的文件列表
                                                    onChange={handleUploadChange}
                                                >
                                                    <img src={addIcon} alt="add icon" className={styles.components_add_img} />
                                                    <p>点击或拖动上传图片</p>
                                                </Upload.Dragger>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className={styles.preview_img_name_box}>
                                    <span>图片名称：</span>
                                    <Input placeholder="请输入新的图片名称" value={imageName} onChange={(e) => {setImageName(e.target.value)}} disabled={addModalStatus === 'add' || fileList.length !== 0 ? true : false} />
                                </div>
                            </div>
                            <Upload
                                fileList={fileList}
                                beforeUpload={(file) => {
                                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                                    if (!isJpgOrPng) {
                                    message.error('目前只允许上传 JPG/PNG 的图片！');
                                    }
                                    const isLt10M = file.size / 1024 / 1024 < 10;
                                    if (!isLt10M) {
                                        message.error('图片目前限制要小于 10MB！');
                                    }
                                    return false;
                                }}
                                showUploadList={false}  // 不显示上传的文件列表
                                onChange={handleUploadChange}
                            >
                                <Button type="primary">更换图片</Button>
                            </Upload>
                        </div>
                        <div className={styles.main_right}>
                            <div className={styles.right_top}>
                                <CustomForm
                                    tableData={tableData}
                                    setTableData={setTableData}
                                    form={form}
                                />
                            </div>
                            <div className={styles.right_bottom}>
                                <CustomTable
                                    tableData={tableData}
                                    setTableData={setTableData}
                                />
                            </div>
                        </div>
                    </div>

                    {
                        currentComponent?.is_active ? null : (
                            <div className={styles.footer}>
                                {
                                    creating ? (
                                        <Button type="primary" loading>
                                            提交中...
                                        </Button>
                                    ) : (
                                        <Button
                                            type='primary'
                                            onClick={handleSubmit}
                                        >
                                            提交
                                        </Button>
                                    )
                                }
                            </div>
                        )
                    }
                </div>

            </Modal>
        </>
    )
}

export default ComponentListedModalPicContentAdd