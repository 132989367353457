import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Tooltip } from 'antd'

import styles from './index.module.css'
import { actionCreators } from '../../store'
import { getLevelFromExperience } from '../../../../utils'

import myStoneBackpackImg from '../../../../statics/side_my_stone_backpack.png'
import expandImg from '../../../../statics/chat_type_expand.png'
import shrinkImg from '../../../../statics/chat_type_shrink.png'
import lockImg from '../../../../statics/side_my_stone_lock.png'
import plusImg from '../../../../statics/side_my_stone_plus.png'

class SideMyStoneBackpack extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: false, // 默认不收缩
            maxHeight: null,
        }
        this.contentRef = React.createRef();
    }

    toggleCollapse() {
        const contentHeight = this.contentRef.current.scrollHeight; // 获取内容的实际高度
        this.setState(prevState => ({
            isCollapsed: !prevState.isCollapsed,
            maxHeight: !prevState.isCollapsed ? 0 : contentHeight + 'px', // 设置 maxHeight 为内容高度或 0
        }))
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.isCollapsed) {
            const contentHeight = this.contentRef.current.scrollHeight;
            if (prevState.maxHeight !== contentHeight + 'px') {
                this.setState({
                    maxHeight: contentHeight + 'px' // 更新 maxHeight 为当前内容的高度
                });
            }
        }
    }

    handleItemKeep(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    render() {
        let component_list = this.props.mall_components_list.toJS()
        let level_space_data = this.props.level_space_data.toJS()
        let levelThresholds = this.props.levelThresholds.toJS()
        const { level, totalExperience } = getLevelFromExperience(this.props.current_experience, levelThresholds);

        let hasAddGreaterThanZero = component_list
            .filter(component => component.has_add > 0)
            .sort((a, b) => a.has_add - b.has_add);

        // 确保数组长度为 10，不足的部分用空对象填充
        const requiredLength = 10;
        const canAddLength = level_space_data[`lv${level}`]
        if (hasAddGreaterThanZero.length < canAddLength) {
            // 有 5 个空可填，当前已填 3 个，还有两个可填的
            hasAddGreaterThanZero = hasAddGreaterThanZero.concat(Array(canAddLength - hasAddGreaterThanZero.length).fill({can_add: true}));
        }

        if (hasAddGreaterThanZero.length < requiredLength) {
            // 总共 10 个窗口，当前已填的窗口
            hasAddGreaterThanZero = hasAddGreaterThanZero.concat(Array(requiredLength - hasAddGreaterThanZero.length).fill({}));
        } else {
            hasAddGreaterThanZero = hasAddGreaterThanZero.slice(0, requiredLength)
        }

        return (
            <div className={styles.container}>
                <div className={styles.main}>
                    <div
                        className={styles.title_item}
                        id="tour4"
                    >
                        <div
                            className={styles.expert_butn_box}
                            onClick={() => {this.handleExpertDetail('')}}
                        >
                            <img src={myStoneBackpackImg} className={styles.img} alt="icon" />
                            <div className={styles.text}>我的背包</div>
                        </div>

                        <div className={styles.shrink_box} onClick={this.toggleCollapse.bind(this)}>
                            {
                                this.state.isCollapsed ? (
                                    <img src={shrinkImg} className={styles.shrink_img} alt="icon" />
                                ) : (
                                    <img src={expandImg} className={styles.shrink_img} alt="icon" />
                                )
                            }
                        </div>
                    </div>

                    <div
                        ref={this.contentRef}
                        className={`${styles.collapsibleContent} ${this.state.isCollapsed ? styles.item_collapsed : styles.item_expanded}`}
                        style={{maxHeight: this.state.maxHeight}}
                    >
                        <div className={styles.model_list}>
                            {
                                hasAddGreaterThanZero.map((item, index) => {
                                    // 检查 item 是否具有必要的属性
                                    if (item && item.id) {
                                        return (
                                            <Tooltip placement="top" title={item.name}>
                                                <div
                                                    className={`${styles.item} ${styles.item_active}`}
                                                    key={item.id}
                                                    onClick={() => {this.handleExpertDetail(item.name)}}
                                                >
                                                    <img src={item.avatar} className={styles.item_img} alt="icon" />
                                                </div>
                                            </Tooltip>
                                        )
                                    } else if (item && item.can_add) {
                                        return (
                                            // 使用一个组合的 key，确保每个元素的 key 是唯一和稳定的
                                            <div
                                                className={`${styles.item} ${styles.item_plus}`}
                                                key={`empty-${index}`}
                                                onClick={() => {this.handleExpertDetail('')}}
                                            >
                                                <img src={plusImg} className={styles.item_plus_img} alt={"icon"} />
                                            </div>
                                        )
                                    } else {
                                        return (
                                            // 使用一个组合的 key，确保每个元素的 key 是唯一和稳定的
                                            <div className={`${styles.item} ${styles.item_lock}`} key={`empty-${index}`}>
                                                <img src={lockImg} className={styles.item_lock_img} alt={"icon"} />
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    handleExpertDetail(name) {
        this.props.handleIsShowScreenPersonalOverview(false)
        this.props.handleIsShowScreenStoneModelMall(false)
        this.props.handleIsShowScreenPersonalBackpack(true)
        this.props.handleExpertRelatedAction()
        this.props.handleSetSelectMallComponentName(name)
    }

    handleItemMenu(e) {
        e.preventDefault()
        e.stopPropagation(); // 阻止事件冒泡
    }
}

const mapStateToProps = (state) => {
    return {
        mall_components_list: state.get('home').get('mall_components_list'),
        level_space_data: state.get('home').get('level_space_data'),
        current_experience: state.get('home').get('current_experience'),
        levelThresholds: state.get('home').get('levelThresholds'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsShowScreenPersonalOverview(status) {
            dispatch(actionCreators.isShowScreenPersonalOverview(status))
        },
        handleIsShowScreenStoneModelMall(status) {
            dispatch(actionCreators.isShowScreenStoneModelMall(status))
        },
        handleIsShowScreenPersonalBackpack(status) {
            dispatch(actionCreators.isShowScreenPersonalBackpack(status))
        },
        handleExpertRelatedAction() {
            dispatch(actionCreators.expertRelatedAction())
        },
        handleSetSelectMallComponentName(value) {
            dispatch(actionCreators.setSelectMallComponentName(value))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideMyStoneBackpack)