import { parseISO, format, addHours } from 'date-fns';

var convertUTCToLocalTime = function(utcDateString) {
    // 解析 ISO 格式的 UTC 日期字符串
    const date = parseISO(utcDateString);

    // 东八区 (UTC+8)
    const offset = 8
    const localDate = addHours(date, offset)

    // 格式化日期和时间
    // 您可以根据需要调整 'yyyy-MM-dd HH:mm:ss' 格式字符串
    return format(localDate, 'yyyy-MM-dd HH:mm:ss')
}

var convertGender = function(value) {
    if (value === 'man') {
        return '男'
    } else if (value === 'woman') {
        return '女'
    } else if (value === 'other') {
        return '其他'
    } else {
        return ''
    }
}

var convertCity = function(value) {
    let obj = {
        'beijing': '北京',
        'shanghai': '上海',
        'guangzhou': '广州',
        'shenzhen': '深圳',
        'chengdu': '成都',
        'wuhan': '武汉',
        'haikou': '海口',
        'xian': '西安',
        'dalian': '大连',
    }

    if (obj[value]) {
        return obj[value]
    } else {
        return value
    }
}

var convertOccupation = function(value) {
    let obj = {
        'freelancers': '自由职业者',
        'interior_designer': '室内设计师',
        'other': '其他',
        'student': '学生',
        'architecture_designer': '建筑设计师',
        'individual_enthusiasts': '个人爱好者',
    }

    if (obj[value]) {
        return obj[value]
    } else {
        return value
    }
}


var convertAge = function(value) {
    let obj = {
        '18': '18-24',
        '25': '25-34',
        '35': '35-44',
        '45': '45-54',
        '55': '55-64',
        '65': '65岁以上',
    }

    if (obj[value]) {
        return obj[value]
    } else {
        return value
    }
}

var convertModelName = function(admin_chat_type_options, value) {
    let obj = admin_chat_type_options.find(item => item.value === value)
    if (obj && obj.label) {
        return obj.label
    } else {
        return value
    }
}

var convertModelMenu = function(data) {
    // let d = data.map((item) => {
    //     return {
    //         label: item.name,
    //         key: item.collection,
    //     }
    // })

    // return d
    let picture_kb_arr = []
    let document_kb_arr = []
    let other_arr = []
    data.forEach((item) => {
        let temp = {
            label: item.name,
            key: item.collection,
            r_type: item.r_type,
        }

        if (item.r_type === 'picture_kb') {
            picture_kb_arr.push(temp)
        } else if (item.r_type === 'document_kb') {
            document_kb_arr.push(temp)
        } else {
            other_arr.push(temp)
        }
    })

    let result = [
        {
            key: 'picture_kb',
            label: '图片组件',
            children: picture_kb_arr,
        },
        {
            key: 'document_kb',
            label: '文本组件',
            children: document_kb_arr,
        },
        {
            key: 'other',
            label: '其他',
            children: other_arr,
        },
    ]

    return result
}

var findItemByProperty = function(items, property, value) {
    for (let item of items) {
        if (item[property] === value) {
            return item;
        }

        if (item.children) {
            const found = findItemByProperty(item.children, property, value);
            if (found) {
                return found;
            }
        }
    }

    return null;
}

var arraysAreEqual = function(arr1, arr2) {
    // 首先检查数组长度是否相同
    if (arr1.length !== arr2.length) {
        return false;
    }

    // 使用排序来确保顺序无关
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    // 逐个比较每个元素
    for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
            return false;
        }
    }

    return true;
}

var convertBytesToMB = function(bytes) {
    return (bytes / (1024 * 1024)).toFixed(2);
}

var convertCategorysToLocalFormat = function(data) {
    const transformedData = data.map(item => ({
        value: item.id,
        label: item.name,
        active: false,
    }));

    // 添加元素到数组的开始位置
    transformedData.unshift({ label: '所有', value: 'all', active: true, });

    return transformedData
}

const convertPermissionsToTableData = (permissionsObj) => {
    return Object.entries(permissionsObj).map(([resource, perms], index) => ({
        key: String(index),
        resource,
        permissions: {
            read: perms.includes('read'),
            write: perms.includes('write'),
            execute: perms.includes('execute'),
            delete: perms.includes('delete')
        },
    }));

    // 输入
    // {
    //     "测试1.0": [
    //       "read",
    //       "write",
    //       "execute",
    //       "delete"
    //     ],
    //     "基础AI助手": [
    //       "read",
    //       "write",
    //       "execute",
    //       "delete"
    //     ],
    // }

    // 输出
    // [
    //     {
    //       key: '0',
    //       user: '测试1.0',
    //       permissions: {
    //         read: true,
    //         write: false,
    //         execute: true,
    //         delete: false
    //       }
    //     },
    //     {
    //       key: '1',
    //       user: '基础AI助手',
    //       permissions: {
    //         read: false,
    //         write: true,
    //         execute: false,
    //         delete: false
    //       }
    //     }
    // ]
};

const convertTableDataToPermissions = (tableData) => {
    let permissionsObj = {};

    tableData.forEach(item => {
        let userPermissions = [];

        // 检查每种权限是否被授予，并添加到权限列表中
        if (item.permissions.read) userPermissions.push('read');
        if (item.permissions.write) userPermissions.push('write');
        if (item.permissions.execute) userPermissions.push('execute');
        if (item.permissions.delete) userPermissions.push('delete');

        // 将权限列表与用户名关联在一起
        permissionsObj[item.resource] = userPermissions;
    });

    return permissionsObj;

    // 输入
    // [
    //     {
    //       key: '0',
    //       user: '测试1.0',
    //       permissions: {
    //         read: true,
    //         write: false,
    //         execute: true,
    //         delete: false
    //       }
    //     },
    //     {
    //       key: '1',
    //       user: '基础AI助手',
    //       permissions: {
    //         read: false,
    //         write: true,
    //         execute: false,
    //         delete: false
    //       }
    //     }
    // ]

    // 输出
    // {
    //     "测试1.0": ["read", "execute"],
    //     "基础AI助手": ["write"]
    // }
};

var convertKnowledgeBaseConfigToOptions = function(data) {
    const transformedData = data.map(item => ({
        value: item,
        label: item,
    }));

    return transformedData
}

var mergeWithIsActive = function(arrayA, arrayB) {
    const mergedValuesMap = new Map();

    function mergeItem(item) {
        const existingItem = mergedValuesMap.get(item.label);
        // 如果 map 中没有相同的 label 或者当前项目的 is_active 为 true，则添加或更新到 map 中
        if (!existingItem) {
            mergedValuesMap.set(item.label, item);
        } else {
            // 如果两个项目中任意一个的 is_active 为 true，则保留 is_active 为 true 的项目
            if (item.is_active || existingItem.is_active) {
                mergedValuesMap.set(item.label, item.is_active ? item : existingItem);
            } else {
                // 如果两个项目的 is_active 都为 false，则从 map 中删除该 label
                mergedValuesMap.delete(item.label);
            }
        }
    }

    // 遍历数组 arrayA 和 arrayB 并合并元素
    arrayA.forEach(mergeItem);
    arrayB.forEach(mergeItem);

    // 将 map 转换成数组
    return Array.from(mergedValuesMap.values());
}

var getCurrentFormattedTime = function() {
    const now = new Date();

    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
    const day = now.getDate().toString().padStart(2, '0');

    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

var getCurrentTodayFormattedTime = function() {
    // 获取今天零时零分零秒的时间
    const now = new Date();

    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
    const day = now.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day} 00:00:00`;
}

var getStartOfWeekDate = function() {
    // 获取这周周一的零时零分零秒的时间
    // 当前时间
    const now = new Date();

    // 获取当前是周几（0 表示周日，1 表示周一... 6 表示周六）
    const dayOfWeek = now.getDay();

    // 计算与本周周一的差值
    const distanceToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;

    // 获取本周周一的日期
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() + distanceToMonday);

    // 设置时间为0点0分0秒
    startOfWeek.setHours(0, 0, 0, 0);

    // 格式化输出
    const year = startOfWeek.getFullYear();
    const month = String(startOfWeek.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要+1
    const day = String(startOfWeek.getDate()).padStart(2, '0');
    const hours = String(startOfWeek.getHours()).padStart(2, '0');
    const minutes = String(startOfWeek.getMinutes()).padStart(2, '0');
    const seconds = String(startOfWeek.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function getStartOfMonth() {
    // 获取这个月1号的零时零分零秒的时间
    const now = new Date(); // 获取当前日期和时间
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0); // 设置为这个月1号的0时0分0秒
    const year = startOfMonth.getFullYear();
    const month = (startOfMonth.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
    const day = startOfMonth.getDate().toString().padStart(2, '0');

      return `${year}-${month}-${day}`;
}

var getStartOfToday = function() {
    const now = new Date();

    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
    const day = now.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}

var compareVersions = (v1, v2) => {
    const parts1 = v1.split('.').map(Number);
    const parts2 = v2.split('.').map(Number);

    for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
        const num1 = i < parts1.length ? parts1[i] : 0;
        const num2 = i < parts2.length ? parts2[i] : 0;

        if (num1 > num2) return 1;
        if (num1 < num2) return -1;
    }

    return 0;
}

var incrementVersion = (versionList) => {
    if (!versionList.length) return '1.0.0'; // 如果列表为空，则返回初始版本号

    const sortedList = [...versionList].sort(compareVersions); // 先对现有版本列表进行排序
    const latestVersion = sortedList[sortedList.length - 1]; // 获取最新（最大）的版本号

    const parts = latestVersion.split('.').map(Number); // 将版本号拆分成各个部分并转换为数字
    parts[parts.length - 1] += 1; // 递增最后一部分（修订号）

    return parts.join('.'); // 将修改后的部分重新组合成字符串形式的版本号
}

var transformCategorizeData = (data) => {
    // 定义一个空数组来存储结果
    let result = [];

    // 使用for...in循环遍历对象的键
    for (let key in data) {
        if (data.hasOwnProperty(key)) { // 确保属性属于对象自身，而不是原型链上的属性
            result.push({
                value: data[key],
                name: key
            });
        }
    }

    return result;
}

const componentDocumentIcons = {
    doc: require('../statics/component_document_detail_doc.png'),
    docx: require('../statics/component_document_detail_docx.png'),
    pdf: require('../statics/component_document_detail_pdf.png'),
    excel: require('../statics/component_document_detail_excel.png'),
}

const getMonthStartEndDates = (year) => {
    // 获取当前日期
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth(); // 0为1月，11为12月
    const currentDate = now.getDate();

    // 格式化日期为 'YYYY-MM-DD HH:mm:ss'
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const months = [];

    if (year === currentYear) {
        // 如果是当前年份
        // 只返回当前月的起始和结束时间
        const startDate = new Date(year, currentMonth, 1);
        const endDate = new Date(now); // 今天的日期作为结束日期

        // 格式化起始日期
        const startStr = formatDate(startDate) + ' 00:00:00';

        // 格式化结束日期为今天的时分秒
        const endStr = formatDate(endDate) + ' ' + now.toISOString().split('T')[1].split('.')[0];

        months.push([startStr, endStr]);
    } else {
        // 如果是过去的年份，返回完整的月份范围
        for (let month = 1; month <= 12; month++) {
            // 获取每个月的起始日期（1号）
            const startDate = new Date(year, month - 1, 1);

            // 获取当前月的最后一天
            const endDate = new Date(year, month, 0);  // month 为当前月，0表示该月的最后一天

            // 设置结束日期为23:59:59
            const endStr = formatDate(endDate) + ' 23:59:59';

            // 获取格式化后的起始日期
            const startStr = formatDate(startDate) + ' 00:00:00';

            // 添加到月份数组中
            months.push([startStr, endStr]);
        }
    }

    return months;
}

const getWeeklyStartEndDates = (year, month) => {
    const weeks = [];

    // 获取今天的日期（当前日期）
    const today = new Date();

    // 如果传入的月份是未来的月份，直接返回空数组
    if (year > today.getUTCFullYear() || (year === today.getUTCFullYear() && month > today.getUTCMonth() + 1)) {
        return weeks; // 空数组
    }

    // 获取当前月的第一天和最后一天
    const firstDay = new Date(Date.UTC(year, month - 1, 1));
    const lastDay = new Date(Date.UTC(year, month, 0));

    // 获取当月第一天是周几（0 表示周日，1 表示周一...）
    const firstDayWeek = firstDay.getUTCDay() || 7; // 周日特殊处理为 7
    const lastDayWeek = lastDay.getUTCDay() || 7; // 周日特殊处理为 7

    // 获取周的开始时间（第一周的周一）
    const startOfFirstWeek = new Date(firstDay);
    startOfFirstWeek.setUTCDate(firstDay.getUTCDate() - (firstDayWeek - 1));

    // 获取当前日期（今天的日期）
    const todayYear = today.getUTCFullYear();
    const todayMonth = today.getUTCMonth() + 1; // 月份从0开始
    const todayDate = today.getUTCDate();
    const todayWeekday = today.getUTCDay() || 7; // 今天是周几

    // 如果今天的日期在本月范围内，获取今天的日期并限制在本周的当前日
    let endOfLastWeek = new Date(lastDay);
    if (todayYear === year && todayMonth === month) {
        // 只到今天
        endOfLastWeek = today;
    } else {
        endOfLastWeek.setUTCDate(lastDay.getUTCDate() + (7 - lastDayWeek));
    }

    // 遍历每周
    let current = new Date(startOfFirstWeek);
    while (current <= endOfLastWeek) {
        const weekStart = new Date(current);
        const weekEnd = new Date(current);
        weekEnd.setUTCDate(weekStart.getUTCDate() + 6); // 当前周的最后一天

        // 如果是周日，设置时分秒为 23:59:59
        if (weekEnd.getUTCDay() === 0) {
            weekEnd.setUTCHours(23, 59, 59, 999);
        }

        // 如果今天是当前周，限制结束时间为当前时间
        if (weekEnd > today) {
            weekEnd.setTime(today.getTime());
        }

        // 格式化日期为 'YYYY-MM-DD HH:mm:ss'
        const formatDate = (date) => {
            const year = date.getUTCFullYear();
            const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，需要加1
            const day = date.getUTCDate().toString().padStart(2, '0');
            const hours = date.getUTCHours().toString().padStart(2, '0');
            const minutes = date.getUTCMinutes().toString().padStart(2, '0');
            const seconds = date.getUTCSeconds().toString().padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        weeks.push([formatDate(weekStart), formatDate(weekEnd)]);

        // 跳到下一周
        current.setUTCDate(current.getUTCDate() + 7);
    }

    return weeks;
}


export {
    convertUTCToLocalTime,
    convertGender,
    convertCity,
    convertOccupation,
    convertAge,
    convertModelName,
    convertModelMenu,
    findItemByProperty,
    arraysAreEqual,
    convertBytesToMB,
    convertCategorysToLocalFormat,
    convertPermissionsToTableData,
    convertTableDataToPermissions,
    convertKnowledgeBaseConfigToOptions,
    mergeWithIsActive,
    getCurrentFormattedTime,
    getCurrentTodayFormattedTime,
    getStartOfWeekDate,
    getStartOfMonth,
    getStartOfToday,
    compareVersions,
    incrementVersion,
    transformCategorizeData,
    componentDocumentIcons,
    getMonthStartEndDates,
    getWeeklyStartEndDates,
}