import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux'

const useUserLevel = (experience) => {
    const levelThresholdsImmutable = useSelector(state => state.get('home').get('levelThresholds'));
    const levelThresholds = useMemo(() => levelThresholdsImmutable.toJS(), [levelThresholdsImmutable]);
    const [levelInfo, setLevelInfo] = useState({ level: 1, totalExperience: levelThresholds[0] });

    useEffect(() => {
        let userLevel = 1;  // 默认最小等级为 lv1
        let userTotalExperience = levelThresholds[0];  // 默认 lv1 对应的总经验值
        for (let i = 0; i < levelThresholds.length; i++) {
            if (experience <= levelThresholds[i]) {
                userTotalExperience = levelThresholds[i];
                break;
            }
            userLevel = i + 2;
            userTotalExperience = levelThresholds[i + 1];
        }
        setLevelInfo({ level: userLevel, totalExperience: userTotalExperience });
    }, [experience, levelThresholdsImmutable, levelThresholds]);

    return levelInfo;
};

export default useUserLevel;