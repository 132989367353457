import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useMemo } from 'react'

import styles from './index.module.css'
import moreImg from '../../../../statics/chat_type_sel_more.png'
import dollarImg from '../../../../statics/overview_shopping_dollar_icon.png'
import { actionCreators } from '../../store'
import MyBannerCarousel from '../../../../common/MyBannerCarousel'
import pictureImg from '../../../../statics/shopping_model_picture_components_icon.png'
import documentImg from '../../../../statics/shopping_model_document_components_icon.png'


export default function ScreenStoneModelMall() {
    const dispatch = useDispatch()
    const [componentsList, setComponentsList] = useState([])
    let mall_components_list = useSelector(state => state.get('home').get('mall_components_list'))
    let mall_components_categorys = useSelector(state => state.get('home').get('mall_components_categorys'))
    const [bannerList, setBannerList] = useState([])

    useEffect(() => {
        let active_category = ''
        let l = mall_components_categorys.toJS()
        for (let item of l) {
            if (item.active) {
                active_category = item.label
                break
            }
        }

        if (active_category === '热门专区') {
            setComponentsList(mall_components_list.toJS())
        } else if (active_category) {
            let d = []
            mall_components_list.toJS().forEach(item => {
                if (item.category === active_category) {
                    d.push(item)
                }
            })

            setComponentsList(d)
        }
    }, [mall_components_list, mall_components_categorys])

    useEffect(() => {
        dispatch(actionCreators.getBannerImagesList())
            .then((data) => { setBannerList(data.data) })
            .catch(() => {})
            .finally(() => {})
    }, [])

    const handleShowMore = () => {
        dispatch(actionCreators.isShowScreenPersonalOverview(false))
        dispatch(actionCreators.isShowScreenStoneModelMall(false))
        dispatch(actionCreators.isShowScreenPersonalBackpack(true))
        dispatch(actionCreators.expertRelatedAction())
    }

    const handleClickComponent = (id) => {
        let list = mall_components_list.toJS()
        let new_list = list.map((item) => {
            if (item.id === id) {
                item.has_select = true
            } else {
                item.has_select = false
            }
            return item
        })
        dispatch(actionCreators.setMallComponentsList(new_list))
    }

    let currentComponent = useMemo(() => {
        let list = mall_components_list.toJS()
        let obj = list.find(item => item.has_select === true)
        return obj ? obj : {}
    }, [mall_components_list])

    const handleCategoryChange = (label) => {
        let l = mall_components_categorys.toJS()
        l.forEach(element => {
            if (element.label === label) {
                element.active = true
            } else {
                element.active = false
            }
        })

        dispatch(actionCreators.setMallComponentsCategorys(l))
    }

    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <div className={styles.container_header}>
                    灵石商店
                </div>

                <div className={styles.container_banner}>
                    <MyBannerCarousel data={bannerList} />
                </div>

                <div className={styles.container_divider}></div>

                <div className={styles.container_content}>
                    <div className={styles.container_content_left}>
                        <div className={styles.container_content_left_header}>
                            <div className={styles.header_title}>组件专区</div>
                        </div>

                        <div className={styles.container_content_left_main}>
                            <div className={styles.left_category}>
                                {
                                    mall_components_categorys.toJS().map((item) => {
                                        return (
                                            <div
                                                className={`${styles.category_butn} ${item.active ? styles.category_butn_active : null}`}
                                                key={item.label}
                                                onClick={() => {handleCategoryChange(item.label)}}
                                            >
                                                { item.label }
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className={styles.left_components}>
                                {
                                    componentsList.map((item) => {
                                        return (
                                            <div
                                                className={`${styles.shopping_model_item} ${item.has_select ? styles.shopping_model_item_select : null}`}
                                                key={item.id}
                                                onClick={() => {handleClickComponent(item.id)}}
                                            >
                                                <div className={styles.shopping_model_img_box}>
                                                    <img src={item.avatar} className={styles.shopping_model_img} alt="icon" />
                                                </div>
                                                <div className={`${styles.shopping_model_text_box} ${item.permissions.includes('execute') ? styles.shopping_model_text_box_owned : item.shopping_model_text_box_unowned}`}>
                                                    <div className={styles.shopping_model_text_title}>
                                                        {item.name}
                                                    </div>
                                                    <div className={styles.attached_components_info_box}>
                                                        {
                                                            item.r_type === 'picture_kb' || item.attached_components.length > 0 ? (
                                                                <div className={styles.attached_components_icon_box}>
                                                                    <img src={pictureImg} alt="pic" />
                                                                    <span className={styles.attached_components_icon_text}>图片</span>
                                                                </div>
                                                            ) : null
                                                        }

                                                        {
                                                            item.r_type !== 'picture_kb' ? (
                                                                <div className={styles.attached_components_icon_box}>
                                                                    <img src={documentImg} alt="doc" />
                                                                    <span className={styles.attached_components_icon_text}>文字</span>
                                                                </div>
                                                            ) : null
                                                        }
                                                    </div>
                                                    <div className={styles.shopping_model_text_info}>
                                                        {item.overview}
                                                    </div>
                                                </div>
                                                {
                                                    // item.permissions.includes('execute') ? (
                                                    //     <div className={`${styles.shopping_model_butn} ${styles.shopping_model_owned}`}>
                                                    //         已拥有
                                                    //     </div>
                                                    // ) : (
                                                    //     item.is_limit_time_free ? (
                                                            <div className={`${styles.shopping_model_butn} ${styles.shopping_model_purchase}`}>
                                                                <div>限时免费</div>
                                                            </div>
                                                    //     ) : (
                                                    //         <div className={`${styles.shopping_model_butn} ${styles.shopping_model_purchase}`}>
                                                    //             <div>需支付：</div>
                                                    //             <img src={dollarImg} className={styles.model_status_img} alt={'icon'} />
                                                    //             <div>500</div>
                                                    //         </div>
                                                    //     )
                                                    // )
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className={styles.container_content_right}>
                        <div className={styles.container_content_right_header}>
                            <div className={styles.header_title}>组件详情</div>
                            <div className={styles.more_box}>
                                <div
                                    className={styles.more_text}
                                    onClick={handleShowMore}
                                >
                                    查看我的组件
                                </div>
                                <img src={moreImg} className={styles.more_img} alt="icon" />
                            </div>
                        </div>

                        <div className={styles.container_content_right_main}>
                            {
                                Object.keys(currentComponent).length === 0 ? null : (
                                    <>
                                        <div className={styles.right_header_img_box}>
                                            <img src={currentComponent.avatar} className={styles.right_header_img} alt="icon" />
                                        </div>
                                        <div className={styles.right_header_text_box}>
                                            <div className={styles.right_header_text}>{currentComponent.name}</div>
                                            <div className={styles.right_header_status_box}>
                                                {
                                                    // currentComponent?.permissions?.includes('execute') ? (
                                                    //     <div className={`${styles.shopping_model_butn} ${styles.shopping_model_owned}`}>
                                                    //         已拥有
                                                    //     </div>
                                                    // ) : (
                                                    //     currentComponent.is_limit_time_free ? (
                                                            <div className={`${styles.shopping_model_butn} ${styles.shopping_model_purchase}`}>
                                                                <div>限时免费</div>
                                                            </div>
                                                    //     ) : (
                                                    //         <div className={`${styles.shopping_model_butn} ${styles.shopping_model_purchase}`}>
                                                    //             <div>需支付：</div>
                                                    //             <img src={dollarImg} className={styles.model_status_img} alt={'icon'} />
                                                    //             <div>500</div>
                                                    //         </div>
                                                    //     )
                                                    // )
                                                }
                                            </div>
                                        </div>
                                        <div className={styles.introduce_box}>
                                            <div className={styles.introduce_title}>介绍</div>
                                            <div className={styles.attached_components_info_box}>
                                                {
                                                    currentComponent.r_type === 'picture_kb' || (currentComponent.attached_components && currentComponent.attached_components.length > 0) ? (
                                                        <div className={styles.attached_components_icon_box}>
                                                            <img src={pictureImg} alt="pic" />
                                                            <span className={styles.attached_components_icon_text}>图片</span>
                                                        </div>
                                                    ) : null
                                                }

                                                {
                                                    currentComponent.r_type !== 'picture_kb' ? (
                                                        <div className={styles.attached_components_icon_box}>
                                                            <img src={documentImg} alt="doc" />
                                                            <span className={styles.attached_components_icon_text}>文字</span>
                                                        </div>
                                                    ) : null
                                                }
                                            </div>
                                            <div className={styles.introduce_text}>{currentComponent.overview}</div>
                                        </div>
                                        <div className={styles.example_box}>
                                            <div className={styles.example_title}>例子</div>
                                            {
                                                currentComponent.metadata?.rich_text ? (
                                                    <div className={styles.example_text} dangerouslySetInnerHTML={{ __html: currentComponent.metadata?.rich_text }} />
                                                ) : (
                                                    // <div className={styles.example_text} >{currentComponent.description}</div>
                                                    <div className={styles.example_text} >{""}</div>
                                                )
                                            }
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}