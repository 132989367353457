import React from 'react';


const CustomDivider = ({ children, style }) => {
    const defaultStyle = {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: '#CBD1D8', // 控制文本颜色
        fontSize: '16px',
        ...style, // 允许传入额外的样式
    }

    const lineStyle = {
        flex: 1,
        borderTop: '1px solid #CBD1D8', // 控制分隔线的颜色和厚度
    }

    const textStyle = {
        padding: '0 16px',
    }

    return (
        <div style={defaultStyle}>
            <span style={lineStyle}></span>
            <span style={textStyle}>{children}</span>
            <span style={lineStyle}></span>
        </div>
    )
}

export default CustomDivider