import styles from './index.module.css'
import AddToHomeScreenButton from '../AddToHomeScreenButton'
import AIAssistantButn from '../AIAssistantButn'


function ChatMessageFooter() {
    return (
        <div className={styles.main}>
            <div className={styles.list}>
                <div
                    className={styles.item}
                    id="tour5"
                >
                    <AddToHomeScreenButton />
                </div>
                <div
                    className={styles.item}
                    id="tour6"
                >
                    <AIAssistantButn />
                </div>
            </div>
        </div>
    )
}

export default ChatMessageFooter