import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Select } from 'antd'
import ReactECharts from "echarts-for-react";

import { actionCreators } from '../../store'
import { getMonthStartEndDates, getWeeklyStartEndDates } from '../../../../utils'
import styles from './index.module.css'


const MonthlyDataChart = () => {
    const dispatch = useDispatch()
    const [year, setYear] = useState(2025)
    const [monthlyData, setMonthlyData] = useState([]); // 12 个月数据
    const [weeklyData, setWeeklyData] = useState(null); // 每周数据
    const [selectedMonth, setSelectedMonth] = useState(null); // 被选中的月份
    const [loadingYearly, setLoadingYearly] = useState(false); // 年数据加载状态
    const [loadingWeekly, setLoadingWeekly] = useState(false); // 周数据加载状态
    const [weeklyXData, setWeeklyXData] = useState([])  // ['第1周', '第2周', '第3周', '第4周', '第5周']

    // 加载年份数据
    const loadYearlyData = async (selectedYear) => {
        setLoadingYearly(true);
        setWeeklyData(null); // 切换年份时清空周数据
        let time_list = getMonthStartEndDates(selectedYear)
        let result = []

        for (const item of time_list) {
            try {
                let start_time = item[0]
                let end_time = item[1]

                const data = await dispatch(actionCreators.getUserStatisticsVisitInfo(start_time, end_time))
                result.push(data?.number_of_visitors || 0)
            } catch (error) {
                console.error("加载每月数据失败:", error);
            }
        }

        // 扩展至 12 个元素，每个月份都有对应的数据
        if (result.length < 12) {
            result = result.concat(new Array(12 - result.length).fill(0));
        }
        setMonthlyData(result)
        setLoadingYearly(false);
    };

    const generateWeekStrings = (numWeeks) => {
        const weeksArray = [];

        for (let i = 1; i <= numWeeks; i++) {
            weeksArray.push(`第${i}周`);
        }

        return weeksArray;
    }

    // 加载月份的每周数据
    const loadWeeklyData = async (selectedYear, selectedMonth) => {
        setLoadingWeekly(true);
        let time_list = getWeeklyStartEndDates(selectedYear, selectedMonth)
        setWeeklyXData(generateWeekStrings(time_list.length))

        let result = []

        for (const item of time_list) {
            try {
                let start_time = item[0]
                let end_time = item[1]

                const data = await dispatch(actionCreators.getUserStatisticsVisitInfo(start_time, end_time))
                result.push(data?.number_of_visitors || 0)
            } catch (error) {
                console.error("加载每周数据失败:", error);
            }
        }

        // 扩展至 n 个元素，每周都有对应的数据
        if (result.length < time_list.length) {
            result = result.concat(new Array(time_list.length - result.length).fill(0));
        }
        setWeeklyData(result)
        setSelectedMonth(selectedMonth);
        setLoadingWeekly(false);
    }

    // 当年份变化时加载数据
    useEffect(() => {
        loadYearlyData(year);
    }, [year]);

    // 年数据的柱状图配置
    const yearlyOptions = {
        title: {
            text: `${year} 年每月用户访问人数`,
            left: "center",
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "shadow",
            },
        },
        xAxis: {
            type: "category",
            data: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
        },
        yAxis: {
            type: "value",
        },
        series: [
            {
                name: "人数",
                type: "bar",
                data: monthlyData,
                itemStyle: {
                color: "#73C0DE",
                },
            },
        ],
    };

    // 周数据的柱状图配置
    const weeklyOptions = weeklyData ? {
        title: {
            text: `${year} 年 ${selectedMonth} 月每周用户访问数据`,
            left: "center",
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "shadow",
            },
        },
        xAxis: {
            type: "category",
            data: weeklyXData,
        },
        yAxis: {
            type: "value",
        },
        series: [
            {
                name: "人数",
                type: "bar",
                data: weeklyData,
                itemStyle: {
                color: "#FFA07A",
                },
            },
        ],
    } : null;

    return (
        <div className={styles.chart_box_main}>
            <div className={styles.chart_box_header}>
                {/* 年份选择 */}
                <label>选择年份: </label>
                <Select
                    defaultValue={year}
                    style={{
                        width: 120,
                    }}
                    onChange={(value) => setYear(value)}
                    options={[
                        {
                            value: 2024,
                            label: '2024',
                        },
                        {
                            value: 2025,
                            label: '2025',
                        },
                    ]}
                />
            </div>

            <div className={styles.chart_box_item}>
                {/* 年数据的柱状图 */}
                {
                    loadingYearly ? (
                        <p style={{fontSize: '25px'}}>年数据加载中...</p>
                    ) : (
                        <ReactECharts
                            option={yearlyOptions}
                            style={{ height: "400px", width: "100%" }}
                            onEvents={{
                                click: (params) => {
                                    const monthIndex = params.dataIndex + 1; // 获取点击的月份 (dataIndex 从 0 开始)
                                    console.log("点击了月份");
                                    loadWeeklyData(year, monthIndex);
                                },
                            }}
                        />
                    )
                }
            </div>

            <div className={styles.chart_box_item}>
                {/* 每周数据的柱状图 */}
                {
                    loadingWeekly ? (
                        <p style={{fontSize: '25px'}}>每周数据加载中...</p>
                    ) : (
                        weeklyOptions && (
                            <ReactECharts
                                option={weeklyOptions}
                                style={{ height: "400px", width: "100%", marginTop: "20px" }}
                            />
                        )
                    )
                }
            </div>
        </div>
    )
}

const RecentUserStatistics = () => {
    return (
        <div>
            <div className={styles.header}>
                <div className={styles.title}>
                    近期用户统计信息
                </div>
            </div>

            <div className={styles.content}>
                <MonthlyDataChart />
            </div>
        </div>
    )
}

export default RecentUserStatistics;