export const SET_PROMPT_ACTION = 'home/SET_PROMPT_ACTION'
export const IS_THINKING_ACTION = 'home/IS_THINKING_ACTION'
export const IS_STOP_FETCH_RESPONDING_ACTION = 'home/IS_STOP_FETCH_RESPONDING_ACTION'
export const ALL_CHAT_DATA_ACTION = 'home/ALL_CHAT_DATA_ACTION'
export const ACTIVE_TOPICID_ACTION = 'home/ACTIVE_TOPICID_ACTION'
export const ADD_NEW_CONVERSATION_ACTION = 'home/ADD_NEW_CONVERSATION_ACTION'
export const IS_ZOOM_ACTION = 'home/IS_ZOOM_ACTION'
export const IS_USERINFO_MODAL_OPEN_ACTION = 'home/IS_USERINFO_MODAL_OPEN_ACTION'
export const IS_FEEDBACK_MODAL_OPEN_ACTION = 'home/IS_FEEDBACK_MODAL_OPEN_ACTION'
export const FEEDBACK_SCORE_ACTION = 'home/FEEDBACK_SCORE_ACTION'
export const FEEDBACK_CONTENT_ACTION = 'home/FEEDBACK_CONTENT_ACTION'
export const LANGUAGE_ACTION = 'home/LANGUAGE_ACTION'
export const THEME_ACTION = 'home/THEME_ACTION'
export const AVATAR_UPLOAD_FILELIST_ACTION = 'home/AVATAR_UPLOAD_FILELIST_ACTION'
export const ZOOM_BUTN_TEXT_ACTION = 'home/ZOOM_BUTN_TEXT_ACTION'
export const IS_SHOW_INTRO_VIDEO_ACTION = 'home/IS_SHOW_INTRO_VIDEO_ACTION'
export const IS_SHOW_GUIDE_ACTION = 'home/IS_SHOW_GUIDE_ACTION'
export const IS_SHOW_IMAGE_MOVER_ACTION = 'home/IS_SHOW_IMAGE_MOVER_ACTION'
export const IS_SHOW_BLOCK_USAGE_ACTION = 'home/IS_SHOW_BLOCK_USAGE_ACTION'
export const IS_SHOW_USER_RESEARCH_ACTION = 'home/IS_SHOW_USER_RESEARCH_ACTION'
export const USER_RESEARCH_DATA_ACTION = 'home/USER_RESEARCH_DATA_ACTION'
export const IS_FEEDBACK_DONE_MODAL_OPEN_ACTION = 'home/IS_FEEDBACK_DONE_MODAL_OPEN_ACTION'
export const MENU_SEARCH_CONTENT_ACTION = 'home/MENU_SEARCH_CONTENT_ACTION'
export const MESSAGE_IMAGE_VISIABLE_ACTION = 'home/MESSAGE_IMAGE_VISIABLE_ACTION'
export const MESSAGE_ITEM_IMAGE_PATH_ACTION = 'home/MESSAGE_ITEM_IMAGE_PATH_ACTION'
export const SUGGESTION_LIST_ACTION = 'home/SUGGESTION_LIST_ACTION'
export const ALLOW_PROGRAM_SCROLL_ACTION = 'home/ALLOW_PROGRAM_SCROLL_ACTION'
export const NICKNAME_ACTION = 'home/NICKNAME_ACTION'
export const RESET_STATE = 'home/RESET_STATE'
export const THINKING_MESSAGE_ID_ACTION = 'home/THINKING_MESSAGE_ID_ACTION'
export const IS_UPDATE_INFO_MODAL_OPEN_ACTION = 'home/IS_UPDATE_INFO_MODAL_OPEN_ACTION'
export const KNOWLEDGE_BASE_LIST_ACTION = 'home/KNOWLEDGE_BASE_LIST_ACTION'
export const SET_UPDATE_INFO_ACTION = 'home/SET_UPDATE_INFO_ACTION'
export const SET_IS_MODAL_EXPERIENCE_OPEN = 'home/SET_IS_MODAL_EXPERIENCE_OPEN'
export const SET_IS_EXPERIENCE_CONFETTI_OPEN = 'home/SET_IS_EXPERIENCE_CONFETTI_OPEN'
export const SET_CURRENT_EXPERIENCE = 'home/SET_CURRENT_EXPERIENCE'
export const SET_LEVEL_THRESHOLDS = 'home/SET_LEVEL_THRESHOLDS'
export const IS_SHOW_SCREEN_PERSONAL_OVERVIEW = 'home/IS_SHOW_SCREEN_PERSONAL_OVERVIEW'
export const IS_SHOW_SCREEN_STONE_MODEL_MALL = 'home/IS_SHOW_SCREEN_STONE_MODEL_MALL'
export const IS_SHOW_SCREEN_PERSONAL_BACKPACK = 'home/IS_SHOW_SCREEN_PERSONAL_BACKPACK'
export const SET_MALL_COMPONENTS_LIST = 'home/SET_MALL_COMPONENTS_LIST'
export const SELECT_MALL_COMPONENT_NAME = 'home/SELECT_MALL_COMPONENT_NAME'
export const SET_MALL_COMPONENTS_CATEGORYS = 'home/SET_MALL_COMPONENTS_CATEGORYS'
export const SET_ASSISTANT_PROMPT = 'home/SET_ASSISTANT_PROMPT'
export const SET_IS_ASSISTANT_ASK = 'home/SET_IS_ASSISTANT_ASK'
export const SET_ASSISTANT_CHAT_MESSAGE_DATA = 'home/SET_ASSISTANT_CHAT_MESSAGE_DATA'
export const ALLOW_ASSISTANT_CONTENT_SCROLL = 'home/ALLOW_ASSISTANT_CONTENT_SCROLL'
export const SET_CHAT_COUNT_DATA = 'home/SET_CHAT_COUNT_DATA'
export const SET_CHAT_COUNT_ERROR_MESSAGE = 'home/SET_CHAT_COUNT_ERROR_MESSAGE'