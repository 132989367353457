import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import styles from './index.module.css'
import { actionCreators } from '../../store'
import MessageInput from '../MessageInput'
import errorMessageImg from '../../../../statics/chat_count_error_message.png'


const MessageInputBox = () => {
    const dispatch = useDispatch()
    let is_thinking = useSelector(state => state.get('home').get('is_thinking'))
    let suggestion_list_mutable = useSelector(state => state.get('home').get('suggestion_list'))
    let active_date = useSelector(state => state.get('home').get('active_date'))
    let active_topicid = useSelector(state => state.get('home').get('active_topicid'))
    let chat_count_error_message = useSelector(state => state.get('home').get('chat_count_error_message'))
    const [currentSuggestionList, setCurrentSuggestionList] = useState([])
    const inputRef = useRef(null);

    useEffect(() => {
        let l = suggestion_list_mutable.toJS()
        let slice_list = l.slice(0, 2)
        setCurrentSuggestionList(slice_list)
    }, [suggestion_list_mutable])

    const onSuggestionClick = (text) => {
        if (text.trim() !== '') {
            dispatch(actionCreators.setPromptAction(text))
        }
    }

    return (
        <div className={styles.container}>
            {
                active_date === '' && active_topicid === '' ? null: (
                    <>
                        <MessageInput
                            ref={inputRef}
                            handleOnBlur={() => {}}
                            handleOnFocus={() => {}}
                        />
                        <div className={styles.textarea_explain}>灵石LinkStone可能会犯错，请考虑核实重要信息。</div>
                    </>
                )
            }

            {
                is_thinking || currentSuggestionList.length === 0 ? null : (
                    <div className={styles.suggestion_box}>
                        {/* <div className={styles.suggestion_title}>猜你也许想问：</div> */}
                        <div className={styles.suggestion_item_box}>
                            {
                                currentSuggestionList.map((item, index) => {
                                    return (
                                        <div
                                            className={`
                                                ${styles.item}
                                                ${currentSuggestionList.length === 1 ? styles.item1 : (currentSuggestionList.length === 2 ? styles.item2 : styles.item3)}
                                            `}
                                            title={item.text}
                                            key={index}
                                            onClick={() => { onSuggestionClick(item.text) }}
                                        >
                                            <span className={styles.item_text}>{item.text} →</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            }

            {
                is_thinking ? null : (
                    chat_count_error_message ? (
                        <div className={styles.error_box}>
                            <div className={styles.error_message_box}>
                                <div className={styles.error_message}>
                                    <img src={errorMessageImg} className={styles.error_icon} alt="icon" />
                                    <span>{chat_count_error_message}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        currentSuggestionList.length === 0 ? null : (
                            <div className={styles.suggestion_box}>
                                {/* <div className={styles.suggestion_title}>猜你也许想问：</div> */}
                                <div className={styles.suggestion_item_box}>
                                    {
                                        currentSuggestionList.map((item, index) => {
                                            return (
                                                <div
                                                    className={`
                                                        ${styles.item}
                                                        ${currentSuggestionList.length === 1 ? styles.item1 : (currentSuggestionList.length === 2 ? styles.item2 : styles.item3)}
                                                    `}
                                                    title={item.text}
                                                    key={index}
                                                    onClick={() => { onSuggestionClick(item.text) }}
                                                >
                                                    <span className={styles.item_text}>{item.text} →</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    )
                )
            }
        </div>
    )
}

export default MessageInputBox