import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, message, Select, Input, Checkbox, DatePicker } from 'antd'
import { useLocation } from 'react-router-dom'

import styles from './index.module.css'
import { actionCreators } from '../../store'

function CategorizeGenerateManagementUpdate() {
    const dispatch = useDispatch()
    const location = useLocation()
    const [count, setCount] = useState('1')
    const [isDelete, setIsDelete] = useState(false)
    const [category, setCategory] = useState('vector')     // llm vector
    const [creating, setCreating] = useState(false)
    const [countMessage, setCountMessage] = useState('0 / 0')     // llm vector
    const [currentTimes, setCurrentTimes] = useState([])
    console.log("currentTimes = ", currentTimes);

    const updateCountMessage = () => {
        let start_time = currentTimes.length > 0 ? currentTimes[0] : ''
        let end_time = currentTimes.length > 0 ? currentTimes[1] : ''

        dispatch(actionCreators.fetchCategoryMatchNumber(category, start_time, end_time))
        .then((data) => {
            console.log("data = ", data);
            let d = data.data
            setCountMessage(`${d.matched_messaged_count} / ${d.messages_count}`)
        }).catch(() => {
        }).finally(() => {
        })
    }

    const updateCategorize = () => {
        if (countMessage === '0 / 0') {
            message.info("请先获取指定日期的问题数")
        } else if (!isNaN(count) && count.trim() !== "") {
            let number = Number(count);
            if (number <= 0) {
                message.info("请输入大于零的正整数")
            } else {
                console.log("category = ", category);
                console.log("number = ", number);
                console.log("isDelete = ", isDelete);
                message.info("数据正在归纳分类中，请稍等...")
                setCreating(true)
                let start_time = currentTimes.length > 0 ? currentTimes[0] : ''
                let end_time = currentTimes.length > 0 ? currentTimes[1] : ''
                dispatch(actionCreators.fetchUpdateUserCategorizeMessage(category, number, isDelete, start_time, end_time))
                    .then(() => {
                        // 判断当前路径是否包含 "/admin"
                        const isAdminRoute = location.pathname.startsWith('/admin')
                        if (isAdminRoute) {
                            updateCountMessage()
                            message.success("用户画像更新完毕！")
                        }
                    }).catch(() => {
                        message.error("用户画像更新失败，请联系管理员！")
                    }).finally(() => {
                        setCreating(false)
                    })
            }
        } else {
            message.info("输入无效，请输入有效数字")
        }
    }

    const onDatePickerChange = (date, dateString) => {
        console.log("onChange ", date, dateString);
        setCurrentTimes(dateString)
        setCountMessage('0 / 0')
        setCount('1')
    };

    const handleGetTimesCountMessage = () => {
        updateCountMessage()
    }

    return (
        <div className={styles.main}>
            <div className={styles.item_box}>
                <div className={styles.item_title}>请选择指定的日期：</div>
                <DatePicker.RangePicker
                    onChange={onDatePickerChange}
                    disabled={creating}
                />
                <Button
                    type='primary'
                    onClick={handleGetTimesCountMessage}
                >
                    获取指定日期的问题数
                </Button>
            </div>

            <div className={styles.item_box}>
                <div className={styles.item_title}>请选择分类的模型：</div>
                <Select
                    defaultValue={category}
                    style={{
                        width: 120,
                    }}
                    onChange={(value) => {setCategory(value)}}
                    options={[
                        {
                            value: 'vector',
                            label: 'vector',
                        },
                        {
                            value: 'llm',
                            label: 'llm',
                        },
                    ]}
                    disabled={creating}
                />
            </div>

            <div className={styles.item_box}>
                <div className={styles.item_title}>需要生成类别的问题数：</div>
                <Input
                    placeholder="需要生成类别的问题数"
                    style={{
                        width: 120,
                    }}
                    value={count}
                    onChange={(e) => {setCount(e.target.value)}}
                />
                <div className={styles.item_count_message}>{countMessage}</div>
            </div>

            <div className={styles.item_box}>
                <div className={styles.item_title}>是否解除之前已经匹配好的所有问题的分类：</div>
                <Checkbox
                    checked={isDelete}
                    onChange={() => {setIsDelete(!isDelete)}}
                >
                    解除之前已匹配的所有分类（谨慎勾选）
                </Checkbox>
            </div>

            <div className={styles.item_box}>
                <div className={styles.item_title}></div>
                {
                    creating ? (
                        <Button type="primary" loading>
                            归纳分类中...
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            onClick={updateCategorize}
                        >
                            更新画像分类信息
                        </Button>
                    )
                }
            </div>
        </div>
    )
}

export default CategorizeGenerateManagementUpdate