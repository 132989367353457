import React, { Component, useState } from 'react';
import { connect } from 'react-redux'
import MarkdownIt from 'markdown-it'
import hljs from 'highlight.js'
import mila from 'markdown-it-link-attributes'
import mdKatex from '@traptitech/markdown-it-katex'
import { message, Tooltip, Popover, Input } from 'antd';

import { actionCreators } from '../../store'
import styles from './index.module.css'
import userAvatar from '../../../../statics/avatar.png'
import userWomanAvatar from '../../../../statics/avatar_woman.png'
// import aiAvatar from '../../../../statics/chatgpt.png'
import aiAvatar from '../../../../statics/ai_link_stone.gif'
import loadingImg from '../../../../statics/loading.gif'


class MessageItem extends Component {
    chatAvatar({role, icon, style}) {
        return (
            <div
                className={styles.avatar}
                style={{
                    ...style
                }}
            >
                <img src={icon} alt="" className={`${role === 'user' ? styles.img : styles.ai_img}`} />
            </div>
        )
    }

    renderMdHtml(value) {
        function highlightBlock(str, lang, code) {
            return `<pre class="code-block-wrapper"><div class="code-block-header"><span class="code-block-header__lang">${lang}</span><span class="code-block-header__copy">复制代码</span></div><code class="hljs code-block-body ${lang}">${str}</code></pre>`
        }

        const mdi = new MarkdownIt({
            html: true,
            linkify: false,     // 禁用自动链接插件
            highlight(code, language) {
              const validLang = !!(language && hljs.getLanguage(language))
              if (validLang) {
                const lang = language ?? ''
                return highlightBlock(hljs.highlight(code, { language: lang }).value, lang, code)
              }
              return highlightBlock(hljs.highlightAuto(code).value, '', code)
            }
        })

        mdi.use(mila, { attrs: { target: '_blank', rel: 'noopener' } })
        mdi.use(mdKatex, { blockClass: 'katex-block', errorColor: ' #cc0000', output: 'mathml' })

        return mdi.render(value)
    }

    renderText(role, text, text_extra_img, text_extra_search, status) {
        text = text + text_extra_img + text_extra_search
        let value = text || ''
        if (role === 'user') {
            return (
                <div className="markdown-body" style={{ whiteSpace: 'pre-wrap'}}>
                    { value }
                </div>
            )
        } else if (status === 'loading' && text === '' && role === 'ai') {
            return <img src={loadingImg} alt="loading..." className={styles.loading_img} />
        } else {
            let render_md_html = this.renderMdHtml(value)
            return (
                <div
                    className="markdown-body"
                    dangerouslySetInnerHTML={{
                        __html: render_md_html
                    }}
                >
                </div>
            )
        }
    }

    render() {
        let item = this.props.ItemData
        let user_data = this.props.user_data.toJS()

        let user_avatar = user_data.avatar ? user_data.avatar : (user_data.gender === 'woman' ? userWomanAvatar : userAvatar)

        return (
            <div className={styles.container}>
                {
                    this.chatAvatar({
                        role: item.role,
                        icon: item.role === 'user' ? user_avatar : aiAvatar,
                        style: { marginRight: 12 },
                    })
                }

                <div className={`${styles.content} ${this.props.is_thinking && item.status === 'loading' ? styles.content_thinking : ''}`}>
                    {/* <span
                        className={styles.content_time}
                    >
                        {item.updated_at}
                    </span> */}
                    <div
                        className={`
                            ${styles.content_text}
                            ${item.role === 'user' ? styles.content_left : styles.content_right}
                            ${item.role === 'user' && item.is_edit ? styles.content_edit : null}
                        `}
                    >
                        {
                            item.is_edit ? (
                                <div className={styles.user_edit_box}>
                                    <Input.TextArea
                                        style={{
                                            resize: 'none',
                                            width: '100%',
                                            height: 100,
                                        }}
                                        placeholder="发送你的问题给灵石AI..."
                                        onChange={(e) => {this.handleUserEditChange(e, item.message_id, this.props.ItemIndex)}}
                                        value={item.edit_text}
                                        className={styles.custom_textarea}
                                    >
                                    </Input.TextArea>
                                    <div className={styles.butn_box}>
                                        <button
                                            className={styles.butn_cancel}
                                            onClick={() => {this.handleCancel(item.message_id, this.props.ItemIndex)}}
                                        >
                                            取消
                                        </button>
                                        <button
                                            className={styles.butn_ok}
                                            onClick={() => {this.handleDone(item.message_id, this.props.ItemIndex)}}
                                        >
                                            完成
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                this.renderText(item.role, item.text, item.text_extra_img, item.text_extra_search, item.status)
                            )
                        }

                        {
                            !this.props.is_thinking && item.role === 'user' && item.is_edit === false ? (
                                <div className={styles.content_text_butns}>
                                    <Tooltip placement="bottom" title={"编辑"}>
                                        <div
                                            className={styles.butn}
                                            onClick={() => {this.handleContentTextButn('edit', item.message_id, '', this.props.ItemIndex)}}
                                        >
                                            <div className={`${styles.butn_img} ${styles.edit_img}`} data-alt="编辑"></div>
                                        </div>
                                    </Tooltip>
                                </div>
                            ) : null
                        }

                        {
                            !this.props.is_thinking && item.role === 'ai' && item.status !== 'loading' ? (
                                <div className={styles.content_text_butns}>
                                    <Tooltip placement="bottom" title={"复制"}>
                                        <div
                                            className={styles.butn}
                                            onClick={() => {this.handleContentTextButn('copy', item.message_id, '', this.props.ItemIndex)}}
                                        >
                                            <div className={`${styles.butn_img} ${styles.copy_img}`} data-alt="复制"></div>
                                        </div>
                                    </Tooltip>

                                    {/* <Tooltip placement="bottom" title={"点赞"}>
                                        <div
                                            className={`${styles.butn} ${item.message_score === 1 ? styles.butn_active : ''}`}
                                            onClick={() => {this.handleContentTextButn('like', item.message_id, item.message_score)}}
                                        >
                                            <div className={`${styles.butn_img} ${styles.like_img}`} data-alt="点赞"></div>
                                        </div>
                                    </Tooltip>

                                    <Tooltip placement="bottom" title={"点踩"}>
                                        <div
                                            className={`${styles.butn} ${item.message_score === 0 ? styles.butn_active : ''}`}
                                            onClick={() => {this.handleContentTextButn('negative', item.message_id, item.message_score)}}
                                        >
                                            <div className={`${styles.butn_img} ${styles.negative_img}`} data-alt="点踩"></div>
                                        </div>
                                    </Tooltip> */}

                                    <Tooltip placement="bottom" title={"重新生成"}>
                                        <div
                                            className={styles.butn}
                                            onClick={() => {this.handleContentTextButn('regenerate', item.message_id, '', this.props.ItemIndex)}}
                                        >
                                            <div className={`${styles.butn_img} ${styles.regenerate_img}`} data-alt="重新生成"></div>
                                        </div>
                                    </Tooltip>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        );
    }

    handleContentTextButn(type, message_id, message_score, index=-1) {
        if (this.props.is_thinking) {
            return;
        }

        if (type === 'edit') {
            this.handleEdit(message_id, index)
        } else if (type === 'like') {
            if (message_score === 1) {
                this.props.handlePostMessageScoreAction(message_id, -1, this.props.active_date, this.props.active_topicid)
            } else {
                this.props.handlePostMessageScoreAction(message_id, 1, this.props.active_date, this.props.active_topicid)
            }
        } else if (type === 'negative') {
            if (message_score === 0) {
                this.props.handlePostMessageScoreAction(message_id, -1, this.props.active_date, this.props.active_topicid)
            } else {
                this.props.handlePostMessageScoreAction(message_id, 0, this.props.active_date, this.props.active_topicid)
            }
        } else if (type === 'regenerate') {
            this.props.handlePostMessageRegenerateAction(message_id, index, this.props.active_date, this.props.active_topicid)
        } else if (type === 'copy') {
            let all_chat_data = this.props.all_chat_data.toJS()
            let active_date = this.props.active_date
            let active_topicid = this.props.active_topicid
            let copy_text = ''
            all_chat_data[active_date]?.forEach((item) => {
                if (item.topic_id === active_topicid) {
                    item.data.forEach((message_item, i) => {
                        if (i === index) {
                            copy_text = message_item.text
                            // copy_text = message_item.text + message_item.text_extra_img + message_item.text_extra_search
                        }
                    })
                }
            })

            this.handleCopy(copy_text)
        }
    }

    handleEdit(message_id, index) {
        let all_chat_data = this.props.all_chat_data.toJS()
        let active_date = this.props.active_date
        let active_topicid = this.props.active_topicid
        all_chat_data[active_date]?.forEach((item) => {
            if (item.topic_id === active_topicid) {
                item.data.forEach((message_item, i) => {
                    if (i === index) {
                        message_item.is_edit = true
                        message_item.edit_text = message_item.text
                    }
                })
            }
        })

        this.props.handleAllChatDataAction(all_chat_data)
    }

    handleUserEditChange(e, message_id, index) {
        let value = e.target.value
        let all_chat_data = this.props.all_chat_data.toJS()
        let active_date = this.props.active_date
        let active_topicid = this.props.active_topicid
        all_chat_data[active_date]?.forEach((item) => {
            if (item.topic_id === active_topicid) {
                item.data.forEach((message_item, i) => {
                    if (i === index) {
                        message_item.edit_text = value
                    }
                })
            }
        })
        this.props.handleAllChatDataAction(all_chat_data)
    }

    handleDone(message_id, index) {
        if (this.props.is_thinking) {
            message.info("正在回答中...")
            return;
        }

        let all_chat_data = this.props.all_chat_data.toJS()
        let active_date = this.props.active_date
        let active_topicid = this.props.active_topicid
        let value = ''
        all_chat_data[active_date]?.forEach((item) => {
            if (item.topic_id === active_topicid) {
                item.data.forEach((message_item, i) => {
                    if (i === index) {
                        value = message_item.edit_text
                        message_item.is_edit = false
                        message_item.edit_text = ''
                    }
                })
            }
        })

        this.props.handleAllChatDataAction(all_chat_data)
        this.props.handleSendAskAction(value)
    }

    handleCancel(message_id, index) {
        let all_chat_data = this.props.all_chat_data.toJS()
        let active_date = this.props.active_date
        let active_topicid = this.props.active_topicid
        all_chat_data[active_date]?.forEach((item) => {
            if (item.topic_id === active_topicid) {
                item.data.forEach((message_item, i) => {
                    if (i === index) {
                        message_item.is_edit = false
                        message_item.edit_text = ''
                    }
                })
            }
        })

        this.props.handleAllChatDataAction(all_chat_data)
    }

    async handleCopy(copy_text) {
        try {
            const copyText = this.removeHtmlElements(copy_text);
            const textToCopy = this.removeMarkdownSyntax(copyText);
            // 使用 Clipboard API 进行复制
            await navigator.clipboard.writeText(textToCopy);

            // 更新状态，显示成功信息
            message.success('复制成功！')
        } catch (err) {
            // 如果复制失败，显示错误信息
            console.error('复制失败:', err);
            message.error('复制失败，请重试');
        }
    }

    removeHtmlElements(str) {
        return str.replace(/<div(.*?)div>|<span(.*?)span>|\((http|https).*?\)|(<(.*?)>)|\[\[[^\]]*\]\]/g, '');
    }

    removeMarkdownSyntax(markdown) {
        // 正则表达式匹配常见的Markdown特殊符号
        const markdownPatterns = [
            /[\*\[\]\(\)>\+#\-]/g, // 移除常见的* [] () > + # -
            /^\s*[0-9]+\./g, // 移除数字列表前缀
            /^\s*[-*+]/g, // 移除无序列表前缀
            /^\s*```\w*\s*/g, // 移除代码块前缀
            /^\s*```\s*/g, // 移除代码块前缀（无语言指定）
            /^\s*<[^>]+>\s*/g, // 移除HTML标签
            /^[\t]*\n/g, // 移除空行
            /\!\[[^\]]*\]\([^\)]*\)/g, // 移除图片链接
        ];
        // 应用所有模式并返回结果
        return markdownPatterns.reduce((prev, pattern) => prev.replace(pattern, ''), markdown);
    }
}

const mapStateToProps = (state) => {
    return {
        active_date: state.get('home').get('active_date'),
        active_topicid: state.get('home').get('active_topicid'),
        is_thinking: state.get('home').get('is_thinking'),
        user_data: state.get('login').get('user_data'),
        all_chat_data: state.get('home').get('all_chat_data'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsThinkingAction(status) {
            dispatch(actionCreators.isThinkingAction(status))
        },
        handlePostMessageScoreAction(message_id, message_score, active_date, active_topicid) {
            dispatch(actionCreators.postMessageScoreAction(message_id, message_score, active_date, active_topicid))
        },
        handlePostMessageRegenerateAction(message_id, index, active_date, active_topicid) {
            dispatch(actionCreators.postMessageRegenerateAction(message_id, index, active_date, active_topicid))
        },
        handleAllChatDataAction(data) {
            dispatch(actionCreators.allChatDataAction(data))
        },
        handleSendAskAction(value) {
            dispatch(actionCreators.sendAskAction(value))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageItem)