import { handleConvertHistoryRecordCiteText } from './parseResponseData'

function generateRandomId() {
    let d = new Date().getTime()
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now() // use high-precision timer if available
    }

    const id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })

    return id
}

var createNewMessage = function({role='user', topic_message_id='', updated_at='', text='', status='pass', image_ids=[], suggestion=[], image_urls=[], search_result=[]}={}) {
    let temp_message_id = generateRandomId()

    let d = {
        id: '',
        message_id: topic_message_id === '' ? temp_message_id : topic_message_id,
        topic_message_id: topic_message_id === '' ? temp_message_id : topic_message_id,
        query_at: updated_at === '' ? dateLocalFormat() : updated_at,
        updated_at: updated_at === '' ? dateLocalFormat() : updated_at,
        text: text,
        text_extra_img: '',
        text_extra_search: '',
        image_ids: [...image_ids],
        image_urls: [...image_urls],
        suggestion: [...suggestion],
        search_result: [...search_result],
        status: status,
        role: role,
        message_score: -1,
        is_edit: false,
        edit_text: '',
    }

    return d;
}

let dateLocalFormat = function() {
    var date = new Date()
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate()

    var hour = date.getHours()
    var minu = date.getMinutes()
    var sec = date.getSeconds()

    month = month >= 10 ? month : '0' + month
    day = day >= 10 ? day : '0' + day
    hour = hour >= 10 ? hour : '0' + hour
    minu = minu >= 10 ? minu : '0' + minu
    sec = sec >= 10 ? sec : '0' + sec

    return `${year}-${month}-${day} ${hour}:${minu}:${sec}`
}

var createNewConversation = function({topic_id='', active=true, name='新的对话'}={}) {
    let d = {
        id: '',
        conversation_id: '',
        conversation_name: name,
        conversation_name_edit: '',
        created_at: '',
        is_edit_name: false,
        favorites_type: '',
        topic_id: topic_id === '' ? generateRandomId() : topic_id,
        active: active,
        data: [],
    }

    return d;
}

var formatResponseDataToLocal = function(data) {
    let conversations = data.conversations
    let messages = data.messages

    return conversations.map((conversation, index) => {
        const conversationId = conversation.conversation_id;
        const messageData = messages[conversationId];

        // 根据 conversation_id 查找对应的消息并转换格式
        const data = messageData ? messageData.reduce((acc, message) => {
            // 总是添加 query 部分
            acc.push({
                id: message.id,
                message_id: message.message_id,
                topic_message_id: `${message.message_id}query`,
                // conversation_id: message.conversation_id,
                query_at: message.query_at,
                updated_at: message.updated_at,
                text: message.query,
                text_extra_img: '',
                text_extra_search: '',
                image_ids: [],
                image_urls: [],
                suggestion: [],
                search_result: [],
                role: 'user',
                status: 'pass',
                message_score: -1,
                is_edit: false,
                edit_text: '',
            });

            // 如果 response 不是空字符串，则添加 response 部分
            if (message.response !== "") {
                acc.push({
                    id: message.id,
                    message_id: message.message_id,
                    topic_message_id: message.message_id,
                    // conversation_id: message.conversation_id,
                    query_at: message.query_at,
                    updated_at: message.updated_at,
                    text: message.response,
                    text_extra_img: '',
                    text_extra_search: '',
                    image_ids: message.response_extra?.images ? message.response_extra?.images : (message.response_extra?.image_ids ? message.response_extra.image_ids : []),
                    suggestion: message.response_extra?.suggestion ? message.response_extra.suggestion : [],
                    search_result: message.response_extra?.search_result ? message.response_extra.search_result : [],
                    image_urls: [],
                    role: 'ai',
                    status: 'pass',
                    message_score: message.feedback_score,
                    is_edit: false,
                    edit_text: '',
                });
            }

            return acc;
        }, []) : []; // 如果没有消息，则 data为空数组

        let conversation_name = conversation.conversation_name
        if (!conversation_name) {
            conversation_name = data[0]?.text ? data[0].text : "新的对话"
        }

        return {
            id: conversation.id,
            // uuid: conversation.uuid,
            conversation_id: conversationId,
            conversation_name: conversation_name,
            conversation_name_edit: '',
            created_at: conversation.created_at,
            is_edit_name: false,
            favorites_type: conversation.favorites_type,
            topic_id: conversationId,
            has_get_images: false,
            active: false,
            // active: index === 0,    // 第一个对话的 active 为 true，其他的为 false
            data: data
        };
    });
}

var generateOptionsByChatData = function(filter_category_list, all_chat_data) {
    let result = [];

    let active_category = filter_category_list.find(option => option.active)?.value

    if (active_category === 'all') {
        // 如果传入'all'，则返回所有类别的数据
        filter_category_list.forEach(filterCategory => {
            if (filterCategory.value !== 'all') { // 排除'all'
                result.push({
                    value: filterCategory.label,
                    data_date: filterCategory.value,
                    data: all_chat_data[filterCategory.value]
                            .filter(conversation => conversation.favorites_type !== '' && conversation.favorites_type !== null)
                            .map(conversation => ({
                                id: conversation.id,
                                conversation_id: conversation.conversation_id,
                                conversation_name: conversation.conversation_name,
                                conversation_name_edit: conversation.conversation_name_edit,
                                created_at: conversation.created_at,
                                is_edit_name: conversation.is_edit_name,
                                favorites_type: conversation.favorites_type,
                                topic_id: conversation.topic_id,
                                has_get_images: conversation.has_get_images,
                                active: conversation.active,
                                data: conversation.data
                            }))
                });
            }
        });
    } else {
        // 否则，只返回特定类别的数据
        let categoryLabel = filter_category_list.find(item => item.value === active_category).label;
        result.push({
            value: categoryLabel,
            data_date: active_category,
            data: all_chat_data[active_category]
                    .filter(conversation => conversation.favorites_type !== '' && conversation.favorites_type !== null)
                    .map(conversation => ({
                        id: conversation.id,
                        conversation_id: conversation.conversation_id,
                        conversation_name: conversation.conversation_name,
                        conversation_name_edit: conversation.conversation_name_edit,
                        created_at: conversation.created_at,
                        is_edit_name: conversation.is_edit_name,
                        favorites_type: conversation.favorites_type,
                        topic_id: conversation.topic_id,
                        has_get_images: conversation.has_get_images,
                        active: conversation.active,
                        data: conversation.data
                    }))
        });
    }

    return result;
    /*
        [
            {
                value: '今天',
                data: [
                    {
                        id: '',
                        conversation_id: '',
                        conversation_name: '新的对话',
                        conversation_name_edit: '',
                        created_at: "2024-06-07 04:00:11",
                        is_edit_name: false,
                        favorites_type: '',
                        topic_id: '123',
                        has_get_images: false,
                        active: false,
                        data: [],
                    },
                ],
            },
        ]
    */
}

var transformDateList = function(chatDateList) {
    return chatDateList.map(item => ({
        'days_start': item.days_start,
        'days_end': item.days_end
    }));
}

var formatResponseConversationsDataToLocal = function(conversations) {
    return conversations.map((conversation, index) => {
        let conversation_name = conversation.conversation_name
        if (!conversation_name) {
            conversation_name = "新的对话"
        }

        return {
            id: conversation.id,
            // uuid: conversation.uuid,
            conversation_id: conversation.conversation_id,
            conversation_name: conversation_name,
            conversation_name_edit: '',
            created_at: conversation.created_at,
            is_edit_name: false,
            favorites_type: conversation.favorites_type,
            topic_id: conversation.conversation_id,
            has_get_images: false,
            active: false,
            // active: index === 0,    // 第一个对话的 active 为 true，其他的为 false
            data: []
        };
    });
}

var formatResponseMessagesDataToLocal = function({data={}, mall_components_list=null}) {
    let messages = data.message

    // 根据 conversation_id 查找对应的消息并转换格式
    const d = messages ? messages.reduce((acc, message) => {
        // 总是添加 query 部分
        acc.push({
            id: message.id,
            message_id: message.message_id,
            topic_message_id: `${message.message_id}query`,
            // conversation_id: message.conversation_id,
            query_at: message.query_at,
            updated_at: message.updated_at,
            text: message.query,
            text_extra_img: '',
            text_extra_search: '',
            image_ids: [],
            image_urls: [],
            suggestion: [],
            search_result: [],
            role: 'user',
            status: 'pass',
            message_score: -1,
            is_edit: false,
            edit_text: '',
        });

        // 如果 response 不是空字符串，则添加 response 部分
        if (message.response !== "") {
            acc.push({
                id: message.id,
                message_id: message.message_id,
                topic_message_id: message.message_id,
                // conversation_id: message.conversation_id,
                query_at: message.query_at,
                updated_at: message.updated_at,
                // text: message.response,
                text: handleConvertHistoryRecordCiteText({message_text: message.response, mall_components_list: mall_components_list === null ? null : mall_components_list}),
                text_extra_img: '',
                text_extra_search: '',
                image_ids: message.response_extra?.images ? message.response_extra?.images : (message.response_extra?.image_ids ? message.response_extra.image_ids : []),
                suggestion: message.response_extra?.suggestion ? message.response_extra.suggestion : [],
                search_result: message.response_extra?.search_result ? message.response_extra.search_result : [],
                image_urls: [],
                role: 'ai',
                status: 'pass',
                message_score: message.feedback_score,
                is_edit: false,
                edit_text: '',
            });
        }

        return acc;
    }, []) : []; // 如果没有消息，则 d 为空数组

    return d
}

var removeHtmlTags = function(html) {
    var div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
}


export {
    generateRandomId,
    createNewMessage,
    createNewConversation,
    formatResponseDataToLocal,
    generateOptionsByChatData,
    transformDateList,
    formatResponseConversationsDataToLocal,
    formatResponseMessagesDataToLocal,
    removeHtmlTags,
}
