import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button } from 'antd'

import MessageNone from '../MessageNone'
import styles from './index.module.css'
import { actionCreators } from '../../store'
import MessageList from '../MessageList'
import { isMessagesEmpty } from '../../../../utils'
import MessageStopFetchResponding from '../MessageStopFetchResponding'
import MessageInputBox from '../MessageInputBox';


class ChatMessage extends Component {
    render() {
        let active_date = this.props.active_date
        let active_topicid = this.props.active_topicid
        let suggestion_list = this.props.suggestion_list.toJS()
        let content_suggestion_classname = suggestion_list.length === 0 ? '' : styles.content_suggestion

        return (
            <div className={styles.container}>
                    {
                        active_date === '' && active_topicid === '' ? (
                            <div className={styles.content_none}>
                                <div className={styles.item_box}>
                                    <MessageNone />
                                </div>
                            </div>
                        ) : (
                            <div className={`${styles.content} ${content_suggestion_classname}`}>
                                <MessageList />
                            </div>
                        )
                    }

                <div className={styles.input_container}>
                    <div className={styles.input_box}>
                        <MessageInputBox />
                    </div>
                </div>

                {
                    this.props.is_stop_fetch_responding ? (
                        <div className={styles.stop_responding}>
                            <MessageStopFetchResponding />
                        </div>
                    ) : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        is_thinking: state.get('home').get('is_thinking'),
        active_date: state.get('home').get('active_date'),
        active_topicid: state.get('home').get('active_topicid'),
        suggestion_list: state.get('home').get('suggestion_list'),
        is_stop_fetch_responding: state.get('home').get('is_stop_fetch_responding'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsThinkingAction(status) {
            dispatch(actionCreators.isThinkingAction(status))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatMessage)