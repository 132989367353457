import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Button, Form, message, Input, } from 'antd';
import MyVerificationCodeInput from '../MyVerificationCodeInput'

import styles from './index.module.css'


// function MyPasswordConfirmModal({ isModalOpen, setIsModalOpen, handlePasswordOk }) {
//     const dispatch = useDispatch()
//     const [form] = Form.useForm()

//     const handleClose = () => {
//         form.resetFields()
//         setIsModalOpen(false)
//     }

//     const onFinish = (values) => {
//         console.log('Success:', values);
//         let username = values.username.trim()
//         let password = values.password.trim()

//         if (username.trim() === '') {
//             message.info("用户名不能全部为空格")
//         } else if (password.trim() === '') {
//             message.info("密码不能全部为空格")
//         } else {
//             handlePasswordOk(username, password)
//         }
//     }

//     const onFinishFailed = (errorInfo) => {
//         console.log('Failed:', errorInfo);
//     }

//     return (
//         <>
//             <Modal
//                 title="该操作需要权限，请先获取验证码并输入"
//                 open={isModalOpen}
//                 onOk={() => {handleClose()}}
//                 onCancel={() => {handleClose()}}
//                 // okText="确认"
//                 // cancelText="取消"
//                 footer={false}
//                 destroyOnClose={true}
//             >
//                 <Form
//                     name="cofirm_password"
//                     labelCol={{
//                         span: 8,
//                     }}
//                     wrapperCol={{
//                         span: 16,
//                     }}
//                     onFinish={onFinish}
//                     onFinishFailed={onFinishFailed}
//                     autoComplete="off"
//                     form={form}
//                     preserve={false}
//                 >
//                     <Form.Item
//                         label="用户名"
//                         name="username"
//                         rules={[
//                             {
//                                 required: true,
//                                 message: '请输入用户名',
//                             },
//                         ]}
//                     >
//                         <Input placeholder="请输入用户名" />
//                     </Form.Item>

//                     <Form.Item
//                         label="密码"
//                         name="password"
//                         rules={[
//                             {
//                                 required: true,
//                                 message: '请输入密码',
//                             },
//                         ]}
//                     >
//                         <Input placeholder="请输入密码" />
//                     </Form.Item>

//                     <Form.Item
//                         wrapperCol={{
//                             offset: 8,
//                             span: 16,
//                         }}
//                     >
//                         <Button type="primary" htmlType="submit">
//                             确认
//                         </Button>
//                     </Form.Item>
//                 </Form>
//             </Modal>
//         </>
//     )
// }

function MyPasswordConfirmModal({ isModalOpen, setIsModalOpen, handlePasswordOk }) {
    const dispatch = useDispatch()
    const [code, setCode] = useState('');

    const handleClose = () => {
        setIsModalOpen(false)
    }

    const onFinish = (values) => {
        console.log('Success:', values);

        if (code.trim() === '') {
            message.info("验证码不能为空")
        } else if (code.trim().length !== 6) {
            message.info("验证码只能为6位")
        } else {
            handlePasswordOk(code.trim())
        }
    }

    return (
        <>
            <Modal
                title="该操作需要权限，请先获取验证码并输入"
                open={isModalOpen}
                onOk={() => {handleClose()}}
                onCancel={() => {handleClose()}}
                // okText="确认"
                // cancelText="取消"
                footer={false}
                destroyOnClose={true}
            >
                <div className={styles.main}>
                    <MyVerificationCodeInput
                        code={code}
                        setCode={setCode}
                    />
                    <Button
                        type="primary"
                        onClick={onFinish}
                    >
                        提交
                    </Button>
                </div>
            </Modal>
        </>
    )
}

export default MyPasswordConfirmModal