import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Select, Button, Modal, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom'

import styles from './index.module.css'
import { actionCreators } from '../../store'
import MyPieChart from '../../../../common/MyPieChart'
import { transformCategorizeData, } from '../../../../utils'
import CategorizeAllManagementTable from '../CategorizeAllManagementTable'

function CategorizeAllManagement() {
    const dispatch = useDispatch()
    const location = useLocation()
    const [categoryData, setCategoryData] = useState([])
    const [ageData, setAgeData] = useState([])
    const [cityData, setCityData] = useState([])
    const [genderData, setGenderData] = useState([])
    const [isShow, setIsShow] = useState(false)
    const [isRequesting, setIsRequesting] = useState(false)
    const [categoryDetailsData, setCategoryDetailsData] = useState([])
    const [loading, setLoading] = useState(true)
    const [paramsName, setParamsName] = useState('')
    const [isVerified, setIsVerified] = useState('yes')     // yes no
    const [category, setCategory] = useState('vector')     // llm vector

    useEffect(() => {
        handleRequest()
    }, [isVerified, category])

    const handleRequest = () => {
        setIsRequesting(true)
        dispatch(actionCreators.fetchAllUserCategorize(isVerified, category))
            .then(data => {
                let d = data.data

                if (d.hasOwnProperty('category')) {
                    setCategoryData(transformCategorizeData(d['category']))
                }

                if (d.hasOwnProperty('age')) {
                    setAgeData(transformCategorizeData(d['age']))
                }

                if (d.hasOwnProperty('city')) {
                    setCityData(transformCategorizeData(d['city']))
                }

                if (d.hasOwnProperty('gender')) {
                    setGenderData(transformCategorizeData(d['gender']))
                }
            })
            .catch(error => {})
            .finally(() => { setIsRequesting(false) })
    }

    const onChartClick = (params) => {
        console.log('你点击了:', params);
        let name = params.name
        // 在这里处理点击事件，例如显示一个弹窗或导航到另一个页面
        setParamsName(name)
        if (name) {
            setIsShow(true)
            setLoading(true)
            dispatch(actionCreators.fetchUserCategorizeDetail(name, category, isVerified))
                .then(data => {
                    let d = data.data
                    const tableData = d.map((item, index) => ({
                        key: item.message_id,
                        value: item.question,
                    }));
                    setCategoryDetailsData(tableData)
                 })
                .catch(error => {})
                .finally(() => { setLoading(false) })
        }
    };

    const handleCategoryCofirm = () => {
        Modal.confirm({
            title: '确认分类完毕',
            content: '请确认该批数据已分类正确！！',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                console.log('OK');
                dispatch(actionCreators.getMessageCategoryVerify(category))
                .then(() => {
                    message.success("确认成功！")
                    handleRequest()
                })
                .catch(() => {message.error("确认失败，请联系管理员！")})
                .finally(() => {})
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.title}>
                    总体用户画像管理
                </div>
                <div className={styles.item_box}>
                    <div className={styles.item_title}>手动确认过的数据：</div>
                    <Select
                        defaultValue={isVerified}
                        style={{
                            width: 120,
                        }}
                        onChange={(value) => {setIsVerified(value)}}
                        options={[
                            {
                                value: 'yes',
                                label: 'yes',
                            },
                            {
                                value: 'no',
                                label: 'no',
                            },
                        ]}
                    />
                    {
                        isVerified === 'no' ? (
                            <Button
                                type="primary"
                                onClick={handleCategoryCofirm}
                            >
                                该批数据分类确认完毕
                            </Button>
                        ) : null
                    }
                </div>

                <div className={styles.item_box}>
                    <div className={styles.item_title}>请选择分类的模型：</div>
                    <Select
                        defaultValue={category}
                        style={{
                            width: 120,
                        }}
                        onChange={(value) => {setCategory(value)}}
                        options={[
                            {
                                value: 'vector',
                                label: 'vector',
                            },
                            {
                                value: 'llm',
                                label: 'llm',
                            },
                        ]}
                    />
                </div>
            </div>

            <div className={styles.container}>
                {
                    isRequesting ? (
                        <div className={styles.container_null}>
                            数据正在请求中，请稍等...... <LoadingOutlined />
                        </div>
                    ) : (
                        <>
                            {
                                categoryData.length > 0 ? (
                                    <div className={styles.container_left}>
                                        <div className={styles.pie_left_item}>
                                            <MyPieChart title={"问题分类"}
                                                data={categoryData}
                                                onChartClick={onChartClick}
                                            />
                                        </div>
                                    </div>
                                ) : null
                            }

                            <div className={styles.container_right}>
                                {
                                    ageData.length > 0 ? (
                                        <div className={styles.pie_right_item}>
                                            <MyPieChart title={"年龄"} data={ageData} />
                                        </div>
                                    ) : null
                                }

                                {
                                    cityData.length > 0 ? (
                                        <div className={styles.pie_right_item}>
                                            <MyPieChart title={"地区"} data={cityData} />
                                        </div>
                                    ) : null
                                }

                                {
                                    genderData.length > 0 ? (
                                        <div className={styles.pie_right_item}>
                                            <MyPieChart title={"性别"} data={genderData} />
                                        </div>
                                    ) : null
                                }
                            </div>
                        </>
                    )
                }
            </div>

            {
                isShow ? (
                    <CategorizeAllManagementTable
                        categoryDetailsData={categoryDetailsData}
                        setCategoryDetailsData={setCategoryDetailsData}
                        loading={loading}
                        setIsShow={setIsShow}
                        paramsName={paramsName}
                        paramsCategoryData={categoryData}
                        category={category}
                        handleRequest={handleRequest}
                        isVerified={isVerified}
                    />
                ) : null
            }
        </div>
    )
}

export default CategorizeAllManagement