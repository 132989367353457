import React, { Component, useState } from 'react';
import { connect } from 'react-redux'
import { Modal, Carousel } from 'antd'

import { actionCreators } from '../../store'
import styles from './index.module.css'
import arrowImg from '../../../../statics/update_done_butn_arrow_forward.png'
import bgImg from '../../../../statics/update_info_bg.png'
import MyButton from '../../../../common/MyButton'


function MyCarousel({ items, handleDone }) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const carouselRef = React.useRef();
    console.log("MyCarousel items = ", items);

    const goToNext = () => {
        console.log("next");
        carouselRef.current.next();
    };

    return (
        <>
            <div className={styles.carousel_container}>
                <Carousel
                    // arrows
                    infinite={false}
                    // fade={true}
                    speed={300}
                    ref={carouselRef}
                    afterChange={(current) => {setCurrentSlide(current); console.log("after current = ", current);}}
                    dots={items.length > 1 ? true : false}
                >
                    {
                        items.map((item) => {
                            return (
                                <div className={styles.carousel_item} key={item}>
                                    <img src={item} className={styles.bg_img} />
                                </div>
                            )
                        })
                    }
                </Carousel>
            </div>

            <div className={styles.footer}>
                <div className={styles.done_butn_box}>
                    {
                        currentSlide + 1 === items.length ? (
                            <MyButton
                                width={'240px'}
                                height={'40px'}
                                onClick={handleDone}
                                text={<><span>开始我的灵石之旅</span></>}
                                type="primary"
                            />
                        ) : (
                            <MyButton
                                width={'240px'}
                                height={'40px'}
                                onClick={goToNext}
                                text={<><span>下一页</span><img src={arrowImg} /></>}
                                type="primary"
                            />
                        )
                    }
                </div>
            </div>
        </>
    );
}

class ModalOverallUpdateInfo extends Component {
    render() {
        let updateInfo = this.props.updateInfo.toJS()

        return (
            <Modal
                open={this.props.isUpdateInfoModalOpen}
                onOk={this.handleDone.bind(this)}
                onCancel={this.handleDone.bind(this)}
                footer={null}
                width={1139}
                height={775}
                centered
                className={styles.modal_container}
            >
                <div className={styles.main}>
                    <MyCarousel
                        items={updateInfo.release?.meta_data?.images || []}
                        handleDone={this.handleDone.bind(this)}
                    />
                </div>
          </Modal>
        )
    }

    handleDone() {
        console.log("done");
        this.props.handleIsUpdateInfoModalOpenAction(false)
        // 当用户关闭了更新提示窗口，往后台发送一个通知
        this.props.handleFetchUserHasReadUpdate()
    }
}

const mapStateToProps = (state) => {
    return {
        isUpdateInfoModalOpen: state.get('home').get('isUpdateInfoModalOpen'),
        updateInfo: state.get('home').get('updateInfo'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsUpdateInfoModalOpenAction(status) {
            dispatch(actionCreators.isUpdateInfoModalOpenAction(status))
        },
        handleFetchUserHasReadUpdate() {
            dispatch(actionCreators.fetchUserHasReadUpdate())
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalOverallUpdateInfo)