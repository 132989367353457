import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Image } from '@tiptap/extension-image';
import React, { useEffect, useState } from 'react';
import { message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import styles from './index.module.css'
import { fetchWithCookieRefresh, handleResponseError } from '../../utils'


const MenuBarButton = ({text, handleClick, disabledStatus=false, classNameStatus,}) => {
    return (
        <div
            className={`${styles.custom_btn} ${classNameStatus ? styles.custom_btn_active : ''}`}
            disabled={disabledStatus}
            onClick={handleClick}
        >
            <span>{ text }</span>
        </div>
    )
}

const MenuBar = ({ editor, editorText }) => {
    const [uploading, setUploading] = useState(false);

    if (!editor) {
        return null;
    }

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        console.log("event.target.files = ", event.target.files);
        if (!file) return;

        setUploading(true);

        // 假设这是你添加的图片的 API
        const formData = new FormData();
        formData.append('image', file);

        // 这里的 URL 应该替换为你实际的图片上传接口
        fetchWithCookieRefresh('/api/admin/component/image/upload', {
            method: 'POST',
            body: formData,
        })
        .then(data => {
            if (data.data?.url) {
                // 使用返回的 URL 插入到编辑器中
                editor.chain().focus().setImage({ src: data.data?.url }).run();
            }
        })
        .catch(error => {
            console.error('添加图片失败:', error);
            handleResponseError(message, error)
        })
        .finally(() => {
            setUploading(false);
        })
    }

    const handleGetHtml = () => {
        const htmlContent = editor.getHTML();
        // const jsonContent = editor.getJSON();
        // const textContent = editor.getText();
        console.log('编辑的HTML内容:', htmlContent);
    }

    // 清空编辑器内容
    const handleClear = () => {
        editor.commands.setContent('');
    }

    // 插入指定的内容
    const handleInsertContent = () => {
        if (editorText) {
            editor.commands.insertContent(editorText);
        }
    }

    return (
        <div className={styles.menu_toolbar}>
            <MenuBarButton
                text="加粗"
                handleClick={() => editor.chain().focus().toggleBold().run()}
                disabledStatus={!editor.can().chain().focus().toggleBold().run()}
                classNameStatus={editor.isActive("bold") ? true : false}
            />
            {/* <button
                onClick={() => editor.chain().focus().toggleBold().run()}
                disabled={!editor.can().chain().focus().toggleBold().run()}
                className={editor.isActive("bold") ? styles.is_active : ""}
            >
                加粗
            </button> */}
            <MenuBarButton
                text="斜体"
                handleClick={() => editor.chain().focus().toggleItalic().run()}
                disabledStatus={!editor.can().chain().focus().toggleItalic().run()}
                classNameStatus={editor.isActive("italic") ? true : false}
            />
            <MenuBarButton
                text="删除线"
                handleClick={() => editor.chain().focus().toggleStrike().run()}
                disabledStatus={!editor.can().chain().focus().toggleStrike().run()}
                classNameStatus={editor.isActive("strike") ? true : false}
            />
            {/* <MenuBarButton
                text="清除格式"
                handleClick={() => editor.chain().focus().unsetAllMarks().run()}
            />
            <MenuBarButton
                text="段落"
                handleClick={() => editor.chain().focus().setParagraph().run()}
                classNameStatus={editor.isActive("paragraph") ? true : false}
            /> */}
            <MenuBarButton
                text="H1"
                handleClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                classNameStatus={editor.isActive("heading", { level: 1 }) ? true : false}
            />
            <MenuBarButton
                text="H2"
                handleClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                classNameStatus={editor.isActive("heading", { level: 2 }) ? true : false}
            />
            <MenuBarButton
                text="H3"
                handleClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                classNameStatus={editor.isActive("heading", { level: 3 }) ? true : false}
            />
            {/* <MenuBarButton
                text="H4"
                handleClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
                classNameStatus={editor.isActive("heading", { level: 4 }) ? true : false}
            />
            <MenuBarButton
                text="H5"
                handleClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
                classNameStatus={editor.isActive("heading", { level: 5 }) ? true : false}
            />
            <MenuBarButton
                text="H6"
                handleClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
                classNameStatus={editor.isActive("heading", { level: 6 }) ? true : false}
            /> */}
            <MenuBarButton
                text="无序列表"
                handleClick={() => editor.chain().focus().toggleBulletList().run()}
                classNameStatus={editor.isActive("bulletList") ? true : false }
            />
            <MenuBarButton
                text="有序列表"
                handleClick={() => editor.chain().focus().toggleOrderedList().run()}
                classNameStatus={editor.isActive("orderedList") ? true : false }
            />
            {/* <MenuBarButton
                text="引用"
                handleClick={() => editor.chain().focus().toggleBlockquote().run()}
                classNameStatus={editor.isActive("blockquote") ? true : false }
            /> */}
            {/* <MenuBarButton
                text="分割线"
                handleClick={() => editor.chain().focus().setHorizontalRule().run()}
            /> */}
            {/* <MenuBarButton
                text="硬换行"
                handleClick={() => editor.chain().focus().setHardBreak().run()}
            /> */}
            {/* <MenuBarButton
                text="撤销"
                handleClick={() => editor.chain().focus().undo().run()}
                disabledStatus={!editor.can().chain().focus().undo().run()}
            />
            <MenuBarButton
                text="重做"
                handleClick={() => editor.chain().focus().redo().run()}
                disabledStatus={!editor.can().chain().focus().redo().run()}
            /> */}
            <MenuBarButton
                text={
                    <label>
                        添加图片
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            style={{ display: 'none' }}
                        />
                    </label>
                }
                handleClick={() => {}}
            />

            {/* <button onClick={handleGetHtml}>获取 HTML 内容</button>

            <button onClick={handleClear}>清空内容</button>

            <button onClick={handleInsertContent}>插入内容</button> */}

            { uploading && <span className={styles.menu_upload_status}>上传中<LoadingOutlined /></span> }
        </div>
    )
}

const MyTipTapTextEditor = ({ editorText, setEditorText }) => {
    // const [editorText, setEditorText] = useState('');
    const editor = useEditor({
        extensions: [
            StarterKit,
            Image, // 使用 Image 扩展
        ],
        content: editorText,
        onUpdate: ({ editor }) => {
            let htmlContent = editor.getHTML();
            htmlContent = htmlContent.replace(/<p><\/p>/g, '<p><br></p>')
            setEditorText(htmlContent)
        },
    })

    return (
        <div className={styles.main}>
            <MenuBar editor={editor} editorText={editorText} />

            <EditorContent editor={editor} />
        </div>
    )
}

export default MyTipTapTextEditor