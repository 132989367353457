import React, { useState, useEffect } from 'react';
import { Table, Space, Input } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux'

import { actionCreators } from '../../store'
import styles from './index.module.css'


const ComponentStatisticsListedTable = ({tableData}) => {
    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('');
    const [data, setData] = useState([]);

    const columns = [
        {
            title: '文件名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '组件名',
            dataIndex: 'component_name',
            key: 'component_name',
        },
        {
            title: '次数',
            dataIndex: 'cite_count',
            sorter: {
                compare: (a, b) => {
                    if (a.cite_count > b.cite_count) {
                        return 1
                    } else if (a.cite_count < b.cite_count) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
            key: 'cite_count',
        },
    ];

    // const handleSearch = () => {
    //     const filteredData = conversation_count_data.toJS().filter((item) =>
    //         !item.username || item.username.includes(searchText.trim())
    //     );
    //     setData(filteredData);
    // }

    return (
        <div className={styles.main}>
            {/* <div className={styles.filter}>
                <Input.Search
                    placeholder="搜索用户名"
                    value={searchText}
                    onSearch={handleSearch}
                    onChange={(e) => setSearchText(e.target.value)}
                    style={{ width: 200, marginBottom: 8 }}
                />
            </div> */}
            <Table
                columns={columns}
                dataSource={tableData}
            />
        </div>
    )
}

export default ComponentStatisticsListedTable