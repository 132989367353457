import React, { Component } from 'react';
import { HashRouter, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Helmet } from 'react-helmet';
import favicon from './statics/favicon.png'

import Home from './pages/home'
import Login from './pages/login'
import Collect from './pages/collect'
import Admin from './pages/admin'
import store from './store'
import { ServiceWorkerContext } from './ServiceWorkerContext'
import { detectDevice } from './utils'


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deferredPrompt: null,
        };
    }

    componentDidMount() {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault(); // 阻止默认事件
            this.setState({ deferredPrompt: e });
        });

        if ('serviceWorker' in navigator) {
            window.addEventListener('load', function() {
                navigator.serviceWorker.register('/static/service-worker.js').then(function(registration) {
                    console.log('ServiceWorker registration successful with scope: ', registration.scope);
                }, function(err) {
                    console.log('ServiceWorker registration failed: ', err);
                });
            });
        }

        // 添加全局事件监听器以阻止所有图片上的上下文菜单
        document.body.addEventListener('contextmenu', this.handleContextMenu);

        window.addEventListener('storage', this.handleStorageChange);

        if (detectDevice() === 'computer_enterprise_wechat') {
            this.handleSetZoom(0.6)
        }
          // 组件挂载时添加窗口大小变化的事件监听器
        window.addEventListener('resize', this.handleResize);
    }

    handleSetZoom(newScale) {
        // document.body.style.zoom = newScale
        document.body.style.transform = `scale(${newScale})`;
        document.body.style.width = `${100 / newScale}vw`;
        document.body.style.height = `${100 / newScale}vh`;
    }

    componentWillUnmount() {
        // 组件卸载时移除事件监听器
        document.body.removeEventListener('contextmenu', this.handleContextMenu);

        window.removeEventListener('storage', this.handleStorageChange);
        // 组件卸载时移除事件监听器以避免内存泄漏
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        // 更新状态以反映新的窗口大小
        let width = window.innerWidth
        let height = window.innerHeight
        if (detectDevice() === 'computer_enterprise_wechat') {
            if (width >= 1601 && height >= 907) {
                this.handleSetZoom(1)
            } else {
                this.handleSetZoom(0.6)
            }
        }
    };

    handleStorageChange = (event) => {
        if (event.key === 'loginState') {
            if (event.newValue) {
                // 更新组件状态
                // const newUser = JSON.parse(event.newValue);
                // console.log('User logged in:', newUser);
                console.log('User logged in');
                window.location.reload()
            } else {
                // 用户已登出
                console.log('User logged out');
                localStorage.removeItem("loginState")
                window.location.reload()
            }
        }
    };

    handleContextMenu = (event) => {
        // 如果事件目标是图片，则阻止默认的上下文菜单
        if (event.target.tagName.toLowerCase() === 'img' || event.target.className === 'msg_images_id_overlay') {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    render() {
        return (
            <Provider store={store}>
                <ServiceWorkerContext.Provider value={this.state.deferredPrompt}>
                    <HashRouter>
                        <Helmet>
                            <link rel="icon" type="image/x-icon" href={favicon} />
                        </Helmet>

                        <Route path="/" exact component={Login}></Route>
                        <Route path="/home" exact component={Home}></Route>
                        <Route path="/collect" exact component={Collect}></Route>
                        <Route path="/admin" exact component={Admin}></Route>
                    </HashRouter>
                </ServiceWorkerContext.Provider>
            </Provider>
        );
    }
}

export default App;
