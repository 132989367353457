import React, { useState } from 'react';
import { Input, Button, message } from 'antd';
import { fetchWithCookieRefresh, handleResponseError } from '../../utils'


const MyVerificationCodeInput = ({ code, setCode }) => {
    // const [code, setCode] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [countdown, setCountdown] = useState(0);

    const handleSendCode = () => {
        if (isSending || countdown > 0) return;

        setIsSending(true)
        fetchWithCookieRefresh('/api/admin/component/auth_code/send', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            message.success('验证码已发送');
            setCountdown(60); // 设置倒计时为60秒
        })
        .catch(error => {
            handleResponseError(message, error)
        })
        .finally(() => {
            setIsSending(false);
        })
    }

    // 倒计时功能
    React.useEffect(() => {
        if (countdown > 0) {
            const timer = setInterval(() => {
                setCountdown(prev => prev - 1);
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [countdown])

    return (
        <div>
            <Input
                placeholder="请输入验证码"
                value={code}
                onChange={e => setCode(e.target.value)}
                style={{ width: '200px', marginRight: '10px' }}
            />
            <Button onClick={handleSendCode} disabled={isSending || countdown > 0}>
                {countdown > 0 ? `重新发送(${countdown}s)` : '发送验证码'}
            </Button>
        </div>
    )
}

export default MyVerificationCodeInput