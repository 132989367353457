import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Select } from 'antd'
import ReactECharts from "echarts-for-react";

import { actionCreators } from '../../store'
import { getMonthStartEndDates } from '../../../../utils'
import styles from './index.module.css'
import ComponentStatisticsListedTable from '../ComponentStatisticsListedTable'


const ComponentDataChart = () => {
    const dispatch = useDispatch()
    const [componentData, setComponentData] = useState([])
    const [loadingComponentData, setLoadingComponentData] = useState(false)
    const [xData, setXData] = useState([])
    const [activeComponentName, setActiveComponentName] = useState('')
    const [tableData, setTableData] = useState([])

    // 当年份变化时加载数据
    useEffect(() => {
        setLoadingComponentData(true)
        dispatch(actionCreators.getComponentStatistics())
            .then((data) => {
                let name_list = []
                let value_list = []
                for (const item of data) {
                    name_list.push(item.name)
                    value_list.push(item.metadata?.cite_count || 0)
                }
                console.log("name_list = ", name_list);
                console.log("value_list = ", value_list);
                setXData(name_list)
                setComponentData(value_list)
            })
            .finally(() => {
                setLoadingComponentData(false)
            })
    }, []);

    useEffect(() => {
        if (activeComponentName) {
            dispatch(actionCreators.getComponentContentStatistics(activeComponentName))
                .then((data) => {
                    let table_list = []
                    for (const item of data) {
                        let obj = {
                            'component_name': activeComponentName,
                            'name': item.name,
                            'cite_count': item.meta_data?.cite_count || 0
                        }
                        table_list.push(obj)
                    }

                    setTableData(table_list)
                })
        }
    }, [activeComponentName])

    // 组件数据的柱状图配置
    const componentOptions = {
        title: {
            text: `文本组件被引用和图片组件中图片被点击的统计信息`,
            left: "center",
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "shadow",
            },
        },
        xAxis: {
            type: "category",
            data: xData,
            axisLabel: {
                rotate: 20,
                margin: 5, // 增加标签距离
            },
        },
        yAxis: {
            type: "value",
        },
        series: [
            {
                name: "次数",
                type: "bar",
                data: componentData,
                itemStyle: {
                color: "#73C0DE",
                },
            },
        ],
    };

    return (
        <div className={styles.chart_box_main}>
            <div className={styles.chart_box_item}>
                {/* 组件统计数据的柱状图 */}
                {
                    loadingComponentData ? (
                        <p style={{fontSize: '25px'}}>数据正在加载中...</p>
                    ) : (
                        <ReactECharts
                            option={componentOptions}
                            style={{ height: "450px", width: "100%" }}
                            onEvents={{
                                click: (params) => {
                                    console.log("点击了组件 params = ", params);
                                    setActiveComponentName(params.name)
                                },
                            }}
                        />
                    )
                }
            </div>

            <div className={styles.table_box_item}>
                <ComponentStatisticsListedTable
                    tableData={tableData}
                />
            </div>
        </div>
    )
}

const ComponentStatisticsListed = () => {
    return (
        <div>
            <div className={styles.header}>
                <div className={styles.title}>
                    组件被引用的统计信息
                </div>
            </div>

            <div className={styles.content}>
                <ComponentDataChart />
            </div>
        </div>
    )
}

export default ComponentStatisticsListed;