import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import UserUsageCard from '../../components/UserUsageCard'
import UserTable from '../../components/UserTable'
import { actionCreators } from '../../store'
import styles from './index.module.css'
import { getCurrentTodayFormattedTime, getStartOfWeekDate, } from '../../../../utils'


const UserStatistics = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actionCreators.fetchUserStatisticsInfo())
        // 获取今天的访问人数
        let start_time = getCurrentTodayFormattedTime()
        dispatch(actionCreators.getUserStatisticsVisitInfo(start_time))
            .then((data) => {
                dispatch(actionCreators.setUserStatisticsTodayLoginNum(data?.number_of_visitors || 0))
            })
        // 获取本周的访问人数
        start_time = getStartOfWeekDate()
        dispatch(actionCreators.getUserStatisticsVisitInfo(start_time))
            .then((data) => {
                dispatch(actionCreators.setUserStatisticsWeekLoginNum(data?.number_of_visitors || 0))
            })
        dispatch(actionCreators.fetchAvgDailyActiveInfo())
    }, [])

    return (
        <div>
            <div className={styles.header}>
                <div className={styles.title}>
                    用户统计信息
                </div>
            </div>

            <div className={styles.card_box}>
                <UserUsageCard />
            </div>

            <div className={styles.table_box}>
                <UserTable />
            </div>
        </div>
    )
}

export default UserStatistics;